/** @type {Portal} */
const Portal = {
  settings: null,
  i18n: null,
  store: null,
  router: null,
  auth: null
}

export default Portal

import { Basin, LayerLogAction, MonitoringCheckType, PlotStatus } from '@/enums/aquarius'

export default {
  plotStatus: {
    [PlotStatus.DRAFT]: 'Черновик',
    [PlotStatus.SENT_TO_TRADING_PLATFORM]: 'Заявка отправлена на торговую площадку',
    [PlotStatus.REJECTED_BY_TIMEOUT]: 'Заявка отклонена по истечению срока рассмотрения',
    [PlotStatus.NOT_FOUND]: 'Участок не найден',
    [PlotStatus.PLOT_ID_ALREADY_EXISTS]: 'Уже существует участок с таким идентификатором',
    [PlotStatus.FAR_APPLICATION_FORMATION]: 'Формирование заявления в Росрыболовство',
    [PlotStatus.FAR_APPLICATION_SENT]: 'Заявление отправлено в Росрыболовство',
    [PlotStatus.FAR_APPLICATION_REJECTED]: 'Заявление отклонено Росрыболовством',
    [PlotStatus.AUCTION_EXECUTION]: 'Выставлен на аукцион',
    [PlotStatus.AUCTION_COMPLETED]: 'Заключение договора',
    [PlotStatus.DISBANDED]: 'Расформирован',
    [PlotStatus.IN_USE]: 'В пользовании',
    [PlotStatus.AUCTION_PREPARATION]: 'Подготовка к аукциону',
    [PlotStatus.UNKNOWN]: 'Неизвестный статус'
  },
  basin: {
    [Basin.FAR_EAST]: 'Дальневосточный РХБ',
    [Basin.NORTH]: 'Северный РХБ'
  },
  basinShortName: {
    [Basin.FAR_EAST]: 'Дальневосточный',
    [Basin.NORTH]: 'Северный'
  },
  monitoringCheckType: {
    [MonitoringCheckType.HEALTHCHECK]: 'Доступность системы',
    [MonitoringCheckType.PLOT_CREATION]: 'Формирование границ РВУ',
    [MonitoringCheckType.RTS_HEALTHCHECK]: 'Доступность РТС-тендер',
    [MonitoringCheckType.RTS_AUTHORIZATION]: 'Авторизация в РТС-тендер'
  },
  layerLogAction: {
    [LayerLogAction.LAYER_CREATED]: 'Создание слоя',
    [LayerLogAction.LAYER_RENAMED]: 'Переименование слоя',
    [LayerLogAction.LAYER_DELETED]: 'Удаление слоя',
    [LayerLogAction.LAYER_MADE_VISIBLE]: 'Отображение слоя на интерактивной карте',
    [LayerLogAction.LAYER_MADE_INVISIBLE]: 'Скрытие слоя на интерактивной карте',
    [LayerLogAction.LAYER_VERSION_CREATED]: 'Создание версии слоя',
    [LayerLogAction.LAYER_VERSION_RENAMED]: 'Переименование версии слоя',
    [LayerLogAction.LAYER_VERSION_DELETED]: 'Удаление версии слоя',
    [LayerLogAction.LAYER_VERSION_ACTIVATED]: 'Применение версии слоя',
    [LayerLogAction.LAYER_VERSION_DEACTIVATED]: 'Отмена версии слоя'
  }
}

import { AccountType } from '@/enums/aquarius'

export default {
  accountType: {
    [AccountType.ADMIN]: 'Администратор',
    [AccountType.INVESTOR]: 'Инвестор',
    [AccountType.FOIV]: 'ФОИВ',
    [AccountType.MO]: 'МО',
    [AccountType.INACTIVE]: 'Заблокированный'
  }
}

import _ from 'lodash'
import { RequestError } from '../utils/errors'
import { beforeDestroy } from '../utils/vue'

// TODO: add ability to update user from backend and from given value
//   and check route after user updating
// TODO: add ability to override auth settings of parent route
/**
 * @param {VueConstructor} Vue
 * @param {Object} settings
 * @returns {AuthPlugin}
 */
export function createAuthModule(Vue, settings) {
  const defaultSettings = {
    /** @type {VueRouter} */
    router: undefined,

    /** @type {AxiosInstance} */
    httpClient: undefined,
    login: undefined,
    logout: undefined,
    getUser: undefined,

    localStorageKey: 'authenticated',

    parseUserData: (data) => data,

    groupsKey: 'groups',
    routerAuthKey: 'auth',
    routerAuthPredicateKey: 'authPredicate',

    loginRoute: '/login',
    afterLoginRedirectRoute: '/',
    forbiddenRoute: '/error/403',
    notFoundRoute: '/error/404',
    routesNotToPreserve: []
  }

  /** @type {AuthPlugin} */
  const AuthConstructor = Vue.extend({
    data() {
      return {
        settings: { ...defaultSettings, ...settings },
        ready: false,
        authenticated: false,
        user: {}
      }
    },

    computed: {
      groups() {
        let groups = _.get(this.user, this.settings.groupsKey)

        if (groups == null) {
          groups = []
        } else if (_.isString(groups)) {
          return [groups]
        }

        return groups
      },

      _routesNotToPreserve() {
        return new Set([
          this.settings.loginRoute,
          this.settings.afterLoginRedirectRoute,
          this.settings.forbiddenRoute,
          this.settings.notFoundRoute,
          ...this.settings.routesNotToPreserve
        ])
      }
    },

    async created() {
      await this._initAuth()
      this.$emit('auth', this.authenticated)
    },

    methods: {
      _setAuthenticationStatus(status) {
        this.authenticated = status
        localStorage.setItem(this.settings.localStorageKey, JSON.stringify(status))
      },

      async _initAuth() {
        await new Promise((resolve, reject) => this.settings.router.onReady(resolve, reject))

        this._setRouterInterceptors()
        this._setAPIInterceptors()

        this._setAuthenticationStatus(this._resolveAuthenticationStatus())

        try {
          if (this.authenticated) {
            await this._getUser()
          }
        } catch (e) {
          // silence a request error in order not to display the corresponding message to user
          const silent = e instanceof RequestError && e.original?.response?.status === 401
          if (!silent) {
            throw e
          }
        } finally {
          await this._checkIfCurrentRouteIsAvailable(true)
          this.ready = true
        }
      },

      /**
       * @param {null|Route} [redirectRoute]
       * @returns {Location}
       */
      _getLoginLocationWithRedirect(redirectRoute = null) {
        const location = { path: this.settings.loginRoute }

        // preserve path to restore after login
        if (redirectRoute && !this._routesNotToPreserve.has(redirectRoute.path)) {
          location.query = { redirect: redirectRoute.fullPath }
        }

        return location
      },

      _setRouterInterceptors() {
        const removeInterceptor = this.settings.router.beforeEach((to, from, next) => {
          const routeCheck = to.matched.every(this._isRouteAvailable)

          if (!routeCheck) {
            if (this.authenticated) {
              next(this.settings.forbiddenRoute)
            } else {
              const location = this._getLoginLocationWithRedirect(to)
              next(location)
            }
            return
          }

          next()
        })
        beforeDestroy(this, removeInterceptor)
      },

      _setAPIInterceptors() {
        const interceptorID = this.settings.httpClient.interceptors.response.use(
          (response) => response,
          async (error) => {
            if (error?.request?.status === 401) {
              this._setAuthenticationStatus(false)

              if (this.settings.router.currentRoute.path !== this.settings.loginRoute) {
                const location = this._getLoginLocationWithRedirect(this.settings.router.currentRoute)
                await this.settings.router.push(location)
              }
            }

            throw error
          }
        )
        beforeDestroy(this, () => this.settings.httpClient.interceptors.response.eject(interceptorID))
      },

      _resolveAuthenticationStatus() {
        let authenticated = false

        try {
          const statusFromLocalStorage = JSON.parse(localStorage.getItem(this.settings.localStorageKey))

          if (statusFromLocalStorage === true) {
            authenticated = true
          }
        } catch {}

        return authenticated
      },

      _isRouteAvailable(route) {
        const privileges = route.meta[this.settings.routerAuthKey]
        let check = false

        if (privileges == null) {
          check = true
        } else if (_.isBoolean(privileges)) {
          check = this.authenticated === privileges
        } else if (_.isString(privileges)) {
          check = this.groups.includes(privileges)
        } else if (Array.isArray(privileges)) {
          check = _.difference(privileges, this.groups).length === 0
        } else if (_.isPlainObject(privileges)) {
          // TODO: add ability to use object. For example: { groups: ['group1'] }
          check = this.authenticated
        } else {
          throw new TypeError('Incorrect type of privileges property')
        }

        if (!check) {
          return check
        }

        const checkPredicate = route.meta[this.settings.routerAuthPredicateKey]

        if (!_.isNil(checkPredicate)) {
          if (_.isFunction(checkPredicate)) {
            check = checkPredicate(this.user, route, this.settings.router.currentRoute, this.settings.router)
          } else {
            throw new TypeError('Incorrect type of property to check availability of route')
          }
        }

        return check
      },

      async _checkIfCurrentRouteIsAvailable(preserveIfNotAvailable = true) {
        if (!this.settings.router.currentRoute.matched.every(this._isRouteAvailable)) {
          if (this.authenticated) {
            await this.settings.router.push(this.settings.forbiddenRoute)
          } else {
            const redirectRoute = preserveIfNotAvailable ? this.settings.router.currentRoute : null
            const location = this._getLoginLocationWithRedirect(redirectRoute)
            await this.settings.router.push(location)
          }
        }
      },

      async _getUser() {
        let result
        try {
          result = await this.settings.getUser()
        } catch (e) {
          this._setAuthenticationStatus(false)

          throw e
        }

        this.user = this.settings.parseUserData(result)
      },

      async login(username, password) {
        await this.settings.login(username, password)

        this._setAuthenticationStatus(true)
        await this._getUser()

        let afterLoginRedirectRoute

        // restore earlier requested path if it's present
        if (this.settings.router.currentRoute.query.redirect) {
          const { route: resolvedRoute } = this.settings.router.resolve(
            this.settings.router.currentRoute.query.redirect
          )

          // check if route had to be preserved at all
          // may occur if location was set manually
          // for example, /login?redirect=/login
          if (!this._routesNotToPreserve.has(resolvedRoute.path)) {
            afterLoginRedirectRoute = resolvedRoute.fullPath
          }
        }

        if (!afterLoginRedirectRoute) {
          afterLoginRedirectRoute = this.settings.afterLoginRedirectRoute
        }

        // handle such errors as NavigationDuplicated
        try {
          await this.settings.router.push(afterLoginRedirectRoute)
        } finally {
          this.$emit('login')
        }
      },

      async logout() {
        this.user = {}
        this._setAuthenticationStatus(false)
        await this._checkIfCurrentRouteIsAvailable(false)

        try {
          await this.settings.logout()
        } finally {
          this.$emit('logout')
        }
      }
    }
  })

  return new AuthConstructor()
}

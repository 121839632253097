import { validateURL } from 'geoportal/src/utils/validators'
import { BannerType } from '@/enums/aquarius'
import { requiredPropertyRule } from '@/utils/forms'
import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'

// export const INFOGRAPHICS_NEWS_BANNERS_LIMIT = 5

export const AsidePanelState = Object.freeze({
  BANNERS_LIST: 'banners_list',
  CREATE_BANNER: 'create_banner',
  UPDATE_BANNER: 'update_banner'
})

export const BannerPageType = Object.freeze({
  // MAIN_PAGE: 'main_page',
  FAQ: 'faq',
  // PORTAL_DESCRIPTION: 'portal_description',
  ERROR_PAGE: 'error_page'
})

export const bannerTypeToPageTypes = {
  [BannerType.ADVERTISING]: [
    // BannerPageType.MAIN_PAGE,
    BannerPageType.FAQ,
    // BannerPageType.PORTAL_DESCRIPTION,
    BannerPageType.ERROR_PAGE
  ]
  // [BannerType.INFOGRAPHICS]: [BannerPageType.MAIN_PAGE],
  // [BannerType.NEWS]: [BannerPageType.MAIN_PAGE]
}

export const bannerPageTypeConfig = {
  // [BannerPageType.MAIN_PAGE]: { title: 'Обратная связь', to: '/feedback' },
  [BannerPageType.FAQ]: { title: 'Частые вопросы', to: '/faq' },
  // [BannerPageType.PORTAL_DESCRIPTION]: { title: 'Информация', to: '/portal-description' },
  [BannerPageType.ERROR_PAGE]: { title: 'Страница ошибки', to: '/error/404' }
}

function urlRule(value) {
  return !value || validateURL(value) || 'Некорректный формат URL'
}

export function createBannerFormConfiguration(bannerType) {
  if (bannerType === BannerType.INFOGRAPHICS) {
    return null
  }

  const urlInput = { id: 'url', label: 'Целевая ссылка', bind: { filled: false, rules: [urlRule] } }

  if (bannerType === BannerType.ADVERTISING) {
    urlInput.required = true
    urlInput.bind.rules.push(requiredPropertyRule)
  }

  return createFormConfiguration([[urlInput, formFieldPresets.text]])
}

export function getBannerImageUrl(filename) {
  return `/api/storage/banners/${filename}`
}

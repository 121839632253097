<template>
  <div class="fictitious-login-form">
    <input id="login" type="email" />
    <input id="password" type="password" />
  </div>
</template>

<script>
  export default {
    name: 'FictitiousLoginForm'
  }
</script>

<style lang="scss" scoped>
  .fictitious-login-form {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
</style>

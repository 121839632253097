import { apiHelper } from '@/api/apiHelper'

export const user_contacts = {
  create_contact({ full_name = undefined, phone = undefined, email = undefined }) {
    return apiHelper.post('/backend/user_contacts/create_contact', {
      data: { full_name, phone, email }
    })
  },

  delete_contact({ id }) {
    return apiHelper.post('/backend/user_contacts/delete_contact', {
      data: { id }
    })
  },

  get_contacts_by_user({ user_id = undefined } = {}) {
    return apiHelper.post('/backend/user_contacts/get_contacts_by_user', {
      data: { user_id }
    })
  }
}

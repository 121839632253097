import Vue from 'vue'
import Vuetify, { colors, VTextField, VSelect } from 'vuetify/lib'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify, {
  components: { VTextField, VSelect }
})

const guideColors = {
  guidePrimary1: '#00afaa',
  guidePrimary2: '#bbbbbb',
  guideAccent1: '#004e59',
  guideAccent2: '#ff6c0e',
  guideSecondary1: '#5b6670',
  guideSecondary2: '#7997ab',
  guideSecondary3: '#17a167',
  guideSecondary4: '#dc0e1c'
}

export default new Vuetify({
  theme: {
    themes: {
      // WARN: theme colors should be duplicated in src/styles/variables.scss
      light: {
        primary: guideColors.guidePrimary1,
        secondary: guideColors.guideSecondary1,
        accent: colors.blue.accent4,
        error: guideColors.guideSecondary4,
        info: colors.blue.base,
        success: guideColors.guideSecondary3,
        warning: guideColors.guideAccent2,
        anchor: guideColors.guidePrimary1,

        rtsPrimary: '#e21e32',
        ...guideColors
      }
    },
    options: {
      variations: false
    }
  },
  icons: {
    values: {
      menu: 'mdi-dots-vertical',
      goBack: 'mdi-arrow-left'
    }
  },
  lang: {
    locales: { ru },
    current: 'ru'
  }
})

<template>
  <v-sheet v-if="plot" class="plot-printer">
    <v-btn class="d-print-none" small icon @click="plot = null">
      <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
    </v-btn>

    <v-simple-table>
      <tr v-for="([parameter, value], i) of formattedPlot" :key="i">
        <td>{{ parameter }}</td>
        <td>{{ value }}</td>
      </tr>
      <tr>
        <td>Координаты</td>
        <td>
          <component :is="coordinatesCmp" />
        </td>
      </tr>
    </v-simple-table>

    <img v-if="imageSrc" ref="image" class="mt-3" :src="imageSrc" />
  </v-sheet>
</template>

<script>
  import {
    formatValue,
    plotPrintConfiguration,
    plotPropertiesFormatters
  } from '@/views/MapView/AsidePanel/PlotPanel/plotPanel'
  import { beforeDestroy } from 'geoportal/src/utils/vue'
  import { PLOT_POINTS_NUMBER } from '@/utils/plots'
  import { generatePlotImage } from '@/views/MapView/generatePlotImage'

  const APP_PLOT_PRINTING_CLASS = 'is-plot-printing'

  export default {
    name: 'PlotPrinter',

    data() {
      return {
        plot: null,
        imageSrc: null
      }
    },

    computed: {
      formattedPlot() {
        if (!this.plot) {
          return null
        }

        return plotPrintConfiguration.map((propertyConfiguration) => {
          const propertyValue = this.plot[propertyConfiguration.id]
          const formatter =
            propertyConfiguration.formatter ?? plotPropertiesFormatters[propertyConfiguration.id] ?? formatValue
          return [propertyConfiguration.label, formatter(propertyValue)]
        })
      },

      coordinatesCmp() {
        return {
          render: (h) => {
            let elements
            if (this.plot.coordinates_legacy) {
              if (this.plot.coordinates_legacy.includes('\n')) {
                elements = this.plot.coordinates_legacy.split('\n').map((part) => h('div', undefined, part))
              } else {
                elements = [h('span', undefined, this.plot.coordinates_legacy)]
              }
            } else {
              elements = this.plot.coordinates.coordinates[0]
                .slice(0, PLOT_POINTS_NUMBER)
                .map((coordinates, i) => h('div', undefined, `${i + 1}. ${coordinates.slice().reverse().join(', ')}`))
            }
            return h('div', undefined, elements)
          }
        }
      }
    },

    watch: {
      plot: 'handlePlotChange'
    },

    created() {
      this.$bus.listen(this, 'printPlot', (plot) => {
        this.plot = plot
      })

      const keyUpHandler = (event) => {
        if (this.plot && event.key === 'Escape') {
          this.plot = null
        }
      }
      document.addEventListener('keyup', keyUpHandler)
      beforeDestroy(this, () => document.removeEventListener('keyup', keyUpHandler))
    },

    methods: {
      printPlot() {
        document.documentElement.classList.add(APP_PLOT_PRINTING_CLASS)
        try {
          window.print()
        } finally {
          document.documentElement.classList.remove(APP_PLOT_PRINTING_CLASS)
        }
        this.plot = null
      },

      async handlePlotChange() {
        if (!this.plot) {
          this.imageSrc = null
          return
        }

        if (this.plot.image) {
          this.imageSrc = this.plot.image
        } else {
          this.imageSrc = await generatePlotImage(this.plot.geometry.coordinates)
        }

        await this.$nextTick()

        const $image = /** @type {HTMLImageElement} */ this.$refs.image
        if (!$image.complete) {
          await new Promise((resolve) => $image.addEventListener('load', resolve))
          await this.$nextTick()
        }

        this.printPlot()
      }
    }
  }
</script>

<style lang="scss">
  @media print {
    html.is-plot-printing {
      .v-application {
        .v-application--wrap {
          & > *:not(:is(.plot-printer)) {
            display: none;
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .plot-printer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 20px;
    z-index: 10000;

    .v-btn {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .v-data-table {
      &::v-deep {
        table {
          &,
          tr,
          td {
            border: thin solid map-get($material-theme, 'dividers');
            border-collapse: collapse;
          }

          td {
            padding: 4px 12px;
          }

          td:first-child {
            font-weight: bold;
          }

          td:nth-child(2) {
            min-width: 220px;
          }
        }
      }
    }

    img {
      width: 400px;
      max-width: 100%;
      height: 300px;
      object-fit: contain;
    }
  }
</style>

import { apiHelper } from '@/api/apiHelper'

export const layers = {
  create_layer(data) {
    return apiHelper.post('/backend/layers/create_layer', {
      data
    })
  },

  update_layer(data) {
    return apiHelper.post('/backend/layers/update_layer', {
      data
    })
  },

  delete_layer({ id }) {
    return apiHelper.post('/backend/layers/delete_layer', {
      data: { id }
    })
  },

  get_layer({ id }) {
    return apiHelper.post('/backend/layers/get_layer', {
      data: { id }
    })
  },

  get_layers({ only_visible = undefined, basin = undefined } = {}) {
    return apiHelper.post('/backend/layers/get_layers', {
      data: { only_visible, basin }
    })
  },

  set_layers_order({ basin, required = undefined, additional = undefined } = {}) {
    return apiHelper.post('/backend/layers/set_layers_order', {
      data: { basin, required, additional }
    })
  },

  create_layer_version(data) {
    return apiHelper.post('/backend/layers/create_layer_version', {
      data
    })
  },

  update_layer_version(data) {
    return apiHelper.post('/backend/layers/update_layer_version', {
      data
    })
  },

  delete_layer_version({ id }) {
    return apiHelper.post('/backend/layers/delete_layer_version', {
      data: { id }
    })
  },

  // get_layer_version({ id }) {
  //   return apiHelper.post('/backend/layers/get_layer_version', {
  //     data: { id }
  //   })
  // },

  get_layer_versions({ layer_id }) {
    return apiHelper.post('/backend/layers/get_layer_versions', {
      data: { layer_id }
    })
  },

  get_log({ sorting = undefined, offset = undefined, limit = undefined } = {}) {
    return apiHelper.post('/backend/layers/get_log', {
      data: { sorting, offset, limit }
    })
  }
}

<template>
  <div class="titled-card">
    <slot name="title">
      <div class="block-subtitle">{{ title }}</div>
    </slot>

    <v-sheet class="mt-3 px-4 py-8" :elevation="0">
      <slot />
    </v-sheet>

    <div class="titled-card__actions d-flex justify-end mt-5">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TitledCard',

    props: {
      title: {
        type: String,
        default: null
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->

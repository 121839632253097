<template>
  <!--<div-->
  <!--  class="field d-contents"-->
  <!--  :style="configuration.style"-->
  <!--  :class="[{ required: configuration.required }, configuration.class]"-->
  <!--&gt;-->
  <!--  &lt;!&ndash;<label v-if="label" :for="inputId">{{ label }}</label>&ndash;&gt;-->

  <!--  -->
  <!--</div>-->

  <component
    :is="configuration.cmp"
    :id="inputId"
    ref="input"
    v-model="activeValue"
    class="field"
    :style="configuration.style"
    :class="[{ required: configuration.required }, configuration.class]"
    v-bind="configuration.bind"
    :label="label"
    :disabled="disabled"
    v-on="configuration.on"
  />
</template>

<script>
  export default {
    name: 'Field',

    props: {
      value: undefined,
      configuration: {
        type: Object,
        required: true
      },
      formId: {
        type: String,
        required: true
      },
      isFormDisabled: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      // required to avoid collisions of input ids when there are multiple forms with the same fields
      inputId() {
        return `${this.formId}_${this.configuration.id}`
      },

      label() {
        if (this.configuration.label === false) {
          return undefined
        } else if (this.configuration.label) {
          return this.configuration.label
        } else if (this.configuration.labelTr) {
          return this.$t(this.configuration.labelTr)
        } else {
          return this.configuration.id
        }
      },

      // proxy property which is required
      // because Vue uses different properties and events
      // to implement v-model interface
      // https://vuejs.org/v2/guide/forms.html#Basic-Usage
      activeValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },

      disabled() {
        return this.configuration.bind?.disabled || this.isFormDisabled
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field {
    //&.required label::after {
    //  content: ' *';
    //}

    &.required .v-input::v-deep .v-label::after {
      content: ' *';
      color: #dc0e1c; // TODO
    }

    .v-input--selection-controls {
      margin-top: initial;
    }
  }
</style>

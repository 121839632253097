export default {
  titles: {
    map: 'Интерактивная карта',

    plotsList: 'Список участков',
    faq: 'Частые вопросы',
    // portalDescription: 'Информация',
    monitoring: 'Состояние системы',
    feedback: 'Обратная связь',

    login: 'Вход',
    registration: 'Регистрация',
    passwordReset: 'Сброс пароля',

    userProfile: 'Профиль',
    userNotifications: 'Оповещения',
    users: 'Организации',
    layersManagement: 'Управление слоями',
    bannersManagement: 'Управление баннерами',
    monitoringSettings: 'Настройки мониторинга',
    rtsSettings: 'Настройки РТС-тендер',
    portalSettings: 'Настройки системы',
    errorPage: 'Ошибка'
  }
}

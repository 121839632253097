import _ from 'lodash'
import Portal from '../portal'

export function getRouteTitle(route) {
  if (!route.meta) {
    return
  }

  const { title, titleTr } = route.meta

  let pageTitle

  if (!_.isNil(title)) {
    if (_.isFunction(title)) {
      pageTitle = title()
    } else {
      pageTitle = title
    }
  } else if (!_.isNil(titleTr)) {
    pageTitle = Portal.i18n.t(titleTr)
  }

  return pageTitle
}

export function getRouteComposedTitle(route) {
  const portalTitle = process.env.VUE_APP_PORTAL_TITLE

  const pageTitleParts = route.matched.map(getRouteTitle).filter(Boolean).reverse()
  return pageTitleParts.length ? `${pageTitleParts.join(' - ')} | ${portalTitle}` : portalTitle
}

// TODO: add ability to set title in page component
// should be used as beforeEach router hook
export function setRouteTitle(to, from, next) {
  document.title = getRouteComposedTitle(to)

  next()
}

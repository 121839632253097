import Vue from 'vue'
import { omit } from 'geoportal/src/utils/func'
import { createAuthModule } from 'geoportal/src/plugins/auth'
import Portal from 'geoportal/src/portal'
import api from '@/api'
import router from '@/router'
import { getStoredValueKey } from '@/utils/statePreservation'

const auth = createAuthModule(Vue, {
  router,
  httpClient: api.helper.client,
  login: (login, password) => api.backend.access.login({ login, password }),
  logout: api.backend.access.logout,
  getUser: api.backend.users.get_current_user,

  localStorageKey: getStoredValueKey('authenticated')
})

auth.updateUser = async function () {
  await this._getUser()
}

auth.setUser = function (user) {
  this.user = user
}

// TODO: rename
auth.afterLogin = async function (redirect = true) {
  this._setAuthenticationStatus(true)
  await this._getUser()

  if (!redirect) {
    this.$emit('login')
    return
  }

  let afterLoginRedirectRoute

  // restore earlier requested path if it's present
  if (router.currentRoute.query.redirect) {
    const { route: resolvedRoute } = router.resolve(router.currentRoute.query.redirect)

    // check if route had to be preserved at all
    // may occur if location was set manually
    // for example, /login?redirect=/login
    if (!this._routesNotToPreserve.has(resolvedRoute.path)) {
      afterLoginRedirectRoute = resolvedRoute.fullPath
    }
  }

  if (!afterLoginRedirectRoute) {
    afterLoginRedirectRoute = this.settings.afterLoginRedirectRoute
  }

  // handle such errors as NavigationDuplicated
  try {
    await router.push(afterLoginRedirectRoute)
  } finally {
    this.$emit('login')
  }
}

const TFA_FLAG_NAME = 'tfa_logged_in'

if (window.location.search && window.location.search.includes(TFA_FLAG_NAME)) {
  auth.$once('auth', () => {
    if (TFA_FLAG_NAME in router.currentRoute.query) {
      router.replace({ query: omit(router.currentRoute.query, [TFA_FLAG_NAME]) })
    }

    if (!auth.authenticated) {
      auth.afterLogin()
    }
  })
}

auth.getRtsLoginUrl = function () {
  const rtsSettings = Portal.store.get('portalSettings').rts

  const url = new URL(rtsSettings.auth_base_url)
  url.pathname = rtsSettings.method_login
  url.searchParams.set('client_id', rtsSettings.client_id)
  url.searchParams.set('redirect_uri', `${window.location.origin}/login`)
  url.searchParams.set('response_type', 'code')
  url.searchParams.set(
    'scope',
    'aqua_vostok openid profile roles permissions it2_gateway_api it2_signalr_web offline_access'
  )

  return String(url)
}

const AFTER_RTS_BIND_REDIRECT_KEY = 'afterRtsBindRedirect'

auth.redirectToRtsLoginForm = function (redirectRoutePath) {
  localStorage.setItem(AFTER_RTS_BIND_REDIRECT_KEY, redirectRoutePath)
  window.location.href = this.getRtsLoginUrl()
}

auth.checkIfWasBoundToRts = async function () {
  const routePath = localStorage.getItem(AFTER_RTS_BIND_REDIRECT_KEY)
  localStorage.removeItem(AFTER_RTS_BIND_REDIRECT_KEY)
  if (!routePath) {
    return false
  }

  const rtsAuthorizationCode = router.currentRoute.query.code
  if (!rtsAuthorizationCode) {
    return false
  }

  await api.backend.rts.bind_user({ authorization_code: rtsAuthorizationCode })
  await this.updateUser()

  router.replace(routePath)

  return true
}

export default auth

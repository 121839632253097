import _ from 'lodash'
import Vue from 'vue'
import { make } from 'vuex-pathify'
import { LayerType } from '@/enums/aquarius'
import api from '@/api'
import { AsidePanelState } from '@/views/LayersManagementView/layersManagement'

function getState() {
  return {
    asidePanelState: AsidePanelState.BASIN_SELECTION,
    selectedBasin: null,

    layers: [],

    selectedLayerId: null,
    selectedLayerVersions: [],
    visibleLayerVersionId: null
  }
}

const initialState = getState()

export default {
  namespaced: true,

  state: initialState,

  getters: {
    layersIndex(state) {
      return _.keyBy(state.layers, 'id')
    },

    selectedLayer(state, getters) {
      return getters.layersIndex[state.selectedLayerId]
    },

    layersVersionsIndex(state) {
      return _.keyBy(state.selectedLayerVersions, 'id')
    },

    visibleLayerVersion(state, getters) {
      return getters.layersVersionsIndex[state.visibleLayerVersionId]
    }
  },

  mutations: {
    ...make.mutations(initialState),

    resetState(state) {
      Object.assign(state, getState())
    },

    addLayer(state, layer) {
      state.layers.push(layer)
    },

    replaceLayer(state, layer) {
      const index = state.layers.findIndex((layer_) => layer_.id === layer.id)
      state.layers.splice(index, 1, layer)
    },

    deleteLayer(state, id) {
      const index = state.layers.findIndex((layer) => layer.id === id)
      state.layers.splice(index, 1)
    },

    setLayersOrder(state, layersIds) {
      const layersIndex = this.getters['layersManagement/layersIndex']
      const layersToOrder = _.mapValues(_.invert(layersIds), Number)
      layersIds.forEach((layerId) => {
        const layer = layersIndex[layerId]
        const layerOrder = layersToOrder[layerId]
        Vue.set(layer, 'order', layerOrder)
      })
    },

    addLayerVersion(state, layerVersion) {
      state.selectedLayerVersions.push(layerVersion)
    },

    replaceLayerVersion(state, layerVersion) {
      const index = state.selectedLayerVersions.findIndex((layerVersion_) => layerVersion_.id === layerVersion.id)
      state.selectedLayerVersions.splice(index, 1, layerVersion)
    },

    deleteLayerVersion(state, id) {
      const index = state.selectedLayerVersions.findIndex((layerVersion) => layerVersion.id === id)
      state.selectedLayerVersions.splice(index, 1)
    }
  },

  actions: {
    resetState({ commit }) {
      commit('resetState')
    },

    setAsidePanelState({ state, commit, dispatch }, [asidePanelState, payload = undefined]) {
      const initialState = getState()

      switch (asidePanelState) {
        case AsidePanelState.BASIN_SELECTION:
          commit('setSelectedBasin', initialState.selectedBasin)
          commit('setLayers', initialState.layers)
          break

        case AsidePanelState.LAYER_LIST:
          switch (state.asidePanelState) {
            case AsidePanelState.BASIN_SELECTION:
              commit('setSelectedBasin', payload)
              break
            case AsidePanelState.LAYER: {
              commit('setSelectedLayerId', initialState.selectedLayerId)
              commit('setSelectedLayerVersions', initialState.selectedLayerVersions)
              commit('setVisibleLayerVersionId', initialState.visibleLayerVersionId)
            }
          }

          dispatch('fetchLayers')
          break

        case AsidePanelState.LAYER:
          commit('setSelectedLayerId', payload)
          dispatch('fetchLayerVersions')
      }

      commit('setAsidePanelState', asidePanelState)
    },

    async fetchLayers({ state, commit }) {
      const result = await api.backend.layers.get_layers({ basin: state.selectedBasin })
      commit('setLayers', result)
    },

    async setLayersOrder({ state, commit }, { layersGroup, layersIds }) {
      commit('setLayersOrder', layersIds)
      const result = await api.backend.layers.set_layers_order({ basin: state.selectedBasin, [layersGroup]: layersIds })
      commit('setLayers', result)
    },

    async fetchLayerVersions({ state, commit }) {
      const [layer, layerVersions] = await Promise.all([
        api.backend.layers.get_layer({ id: state.selectedLayerId }),
        api.backend.layers.get_layer_versions({ layer_id: state.selectedLayerId })
      ])
      commit('replaceLayer', layer)
      commit('setVisibleLayerVersionId', layer.active_version_id)
      commit('setSelectedLayerVersions', layerVersions)
    },

    async createAdditionalLayer({ commit }, data) {
      const layer = await api.backend.layers.create_layer({
        ...data,
        type: LayerType.ADDITIONAL
      })
      commit('addLayer', layer)
    },

    async updateLayer({ commit }, { id, data }) {
      const layer = await api.backend.layers.update_layer({ ...data, id })
      commit('replaceLayer', layer)
    },

    async deleteLayer({ state, commit, dispatch }, id) {
      await api.backend.layers.delete_layer({ id })

      if (state.selectedLayerId === id) {
        await dispatch('setAsidePanelState', [AsidePanelState.LAYER_LIST])
      } else {
        commit('deleteLayer', id)
      }
    },

    async createLayerVersion({ commit }, data) {
      const layerVersion = await api.backend.layers.create_layer_version(data)
      commit('addLayerVersion', layerVersion)
    },

    async updateLayerVersion({ commit }, { id, data }) {
      const layer = await api.backend.layers.update_layer_version({ ...data, id })
      commit('replaceLayerVersion', layer)
    },

    async deleteLayerVersion({ commit }, id) {
      await api.backend.layers.delete_layer_version({ id })
      commit('deleteLayerVersion', id)
    }
  }
}

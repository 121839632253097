export default {
  parameter: 'Параметр',
  value: 'Значение',

  noValue: 'Не указано',

  create: 'Создать',
  edit: 'Редактировать',
  update: 'Обновить',
  save: 'Сохранить',
  apply: 'Применить',
  delete: 'Удалить',
  remove: 'Удалить',
  add: 'Добавить',
  select: 'Выбрать',
  deselect: 'Снять выделение',
  reset: 'Сбросить',
  clear: 'Очистить',
  process: 'Обработать',
  upload: 'Загрузить',
  download: 'Загрузить',
  show: 'Показать',
  hide: 'Скрыть',
  open: 'Открыть',
  close: 'Закрыть',
  cancel: 'Отменить',
  pause: 'Приостановить',
  retry: 'Повторить',

  messageTypes: {
    success: 'Успех',
    info: 'Информация',
    warning: 'Предупреждение',
    error: 'Ошибка'
  },

  messages: {
    sseReconnect: 'Соединение на получение событий сервера восстановлено',

    created: 'Элемент успешно создан',
    updated: 'Элемент успешно обновлен',
    deleted: 'Элемент успешно удален'
  },

  confirm: {
    delete: 'Вы уверены, что хотите удалить?'
  },

  errors: {
    sseConnect: 'Произошла ошибка при попытке установки соединения на получение событий сервера',
    noConnection: 'Невозможно выполнить запрос. Возможно, отсутствует соединение с сервером',

    fileRead: 'Во время чтения файла произошла ошибка',

    request: 'Во время совершения запроса произошла ошибка',
    get: 'Во время загрузки данных произошла ошибка',
    create: 'Во время создания произошла ошибка',
    update: 'Во время обновления данных произошла ошибка',
    delete: 'Во время удаления произошла ошибка'
  }
}

<template>
  <v-text-field
    class="password-input"
    :append-icon="isPasswordHidden ? 'mdi-eye-off' : 'mdi-eye'"
    :type="isPasswordHidden ? 'password' : 'text'"
    v-bind="$attrs"
    @click:append="isPasswordHidden = !isPasswordHidden"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
  export default {
    name: 'PasswordInput',
    inheritAttrs: false,

    data() {
      return {
        isPasswordHidden: true
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->

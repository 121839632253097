import _ from 'lodash'
import VueI18n from 'vue-i18n'
import Portal from '../portal'

function saveLocaleInLocalStorage(localStorageLocaleKey, locale) {
  localStorage.setItem(localStorageLocaleKey, locale)
}

function getLocaleFromLocalStorage(localStorageLocaleKey, defaultLocale, availableLocales) {
  let locale = localStorage.getItem(localStorageLocaleKey)

  if (_.isNil(locale) || !availableLocales.includes(locale)) {
    locale = defaultLocale
    saveLocaleInLocalStorage(localStorageLocaleKey, defaultLocale)
  }

  return locale
}

const pluralizationRules = {
  // source: https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
  ru(choice, choicesLength) {
    if (choice === 0) {
      return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1

    if (choicesLength < 4) {
      return !teen && endsWithOne ? 1 : 2
    }
    if (!teen && endsWithOne) {
      return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
    }

    return choicesLength < 4 ? 2 : 3
  }
}

export function installLocalization(Vue, localizationConfig = {}, settings) {
  settings = settings || Portal.settings.localization
  const { defaultLocale, fallbackLocale, localStorageKey, availableLocales } = settings

  Vue.use(VueI18n)
  // kazupon.github.io/vue-i18n/en/
  const i18n = new VueI18n({
    // restore locale from localStorage
    locale: getLocaleFromLocalStorage(localStorageKey, defaultLocale, availableLocales),
    fallbackLocale,
    messages: localizationConfig,
    pluralizationRules
  })

  // automatically save changed locale in localStorage
  i18n._vm.$watch('locale', (locale) => {
    saveLocaleInLocalStorage(localStorageKey, locale)
  })

  return i18n
}

export const PlotStatus = Object.freeze({
  DRAFT: null,
  SENT_TO_TRADING_PLATFORM: 202,
  REJECTED_BY_TIMEOUT: 203,
  NOT_FOUND: 404,
  PLOT_ID_ALREADY_EXISTS: 409,
  FAR_APPLICATION_FORMATION: 600,
  FAR_APPLICATION_SENT: 601,
  FAR_APPLICATION_REJECTED: 602,
  AUCTION_EXECUTION: 603,
  AUCTION_COMPLETED: 604,
  DISBANDED: 605,
  IN_USE: 606,
  AUCTION_PREPARATION: 607,
  UNKNOWN: 666
})

export const TradingPlatform = Object.freeze({
  NONE: null,
  RTS_TENDER: 'rts-tender'
})

export const AccountType = Object.freeze({
  ADMIN: 'admin',
  INVESTOR: 'investor',
  FOIV: 'foiv',
  MO: 'mo',
  INACTIVE: 'inactive'
})

export const Basin = Object.freeze({
  FAR_EAST: 'far_east',
  NORTH: 'north'
})

export const LayerType = Object.freeze({
  FEDERAL_SUBJECTS: 'federal_subjects',
  PERMITTED_ZONES: 'permitted_zones',
  COASTLINES: 'coastlines',
  ZONES: 'zones',
  ISLANDS: 'islands',
  ADDITIONAL: 'additional',
  FISHING_PLOTS: 'fishing_plots'
})

export const LayerVersionStatus = Object.freeze({
  RUNNING: 'running',
  ERROR: 'error',
  FINISHED: 'finished'
})

export const MonitoringCheckType = Object.freeze({
  HEALTHCHECK: 'healthcheck',
  PLOT_CREATION: 'plot_creation',
  RTS_HEALTHCHECK: 'rts_healthcheck',
  RTS_AUTHORIZATION: 'rts_authorization'
})

export const LayerLogAction = Object.freeze({
  LAYER_CREATED: 'layer_created',
  LAYER_RENAMED: 'layer_renamed',
  LAYER_DELETED: 'layer_deleted',
  LAYER_MADE_VISIBLE: 'layer_made_visible',
  LAYER_MADE_INVISIBLE: 'layer_made_invisible',

  LAYER_VERSION_CREATED: 'layer_version_created',
  LAYER_VERSION_RENAMED: 'layer_version_renamed',
  LAYER_VERSION_DELETED: 'layer_version_deleted',
  LAYER_VERSION_ACTIVATED: 'layer_version_activated',
  LAYER_VERSION_DEACTIVATED: 'layer_version_deactivated'
})

export const BannerType = Object.freeze({
  ADVERTISING: 'advertising',
  INFOGRAPHICS: 'infographics',
  NEWS: 'news'
})

export const BannerPlaceType = Object.freeze({
  MAIN_PAGE_INFOGRAPHICS: 'main_page_infographics',
  MAIN_PAGE_NEWS: 'main_page_news',
  MAIN_PAGE_ADVERTISING_LEFT_SIDE: 'main_page_advertising_left_side',
  MAIN_PAGE_ADVERTISING_RIGHT_SIDE: 'main_page_advertising_right_side',
  PORTAL_DESCRIPTION_ADVERTISING: 'portal_description_advertising',
  FAQ_ADVERTISING: 'faq_advertising',
  ERROR_PAGE_ADVERTISING: 'error_page_advertising'
})

import { settings } from './settings'
import { access } from './access'
import { users } from './users'
import { user_contacts } from './user_contacts'
import { rts } from './rts'
import { plots } from './plots'
import { layers } from './layers'
import { banners } from './banners'
import { monitoring } from './monitoring'
import { feedback } from './feedback'
import { notifications } from './notifications'

export default {
  settings,
  access,
  users,
  user_contacts,
  rts,
  plots,
  layers,
  banners,
  monitoring,
  feedback,
  notifications
}

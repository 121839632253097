import _ from 'lodash'
import Portal from '../portal'

/**
 * @param {string} message
 * @param {string} [messageType]
 * @param {any} [parameters]
 */
export function showMessage(message, messageType, parameters) {
  if (Portal.settings.common.showMessage) {
    Portal.settings.common.showMessage(message, messageType, parameters)
  } else {
    // eslint-disable-next-line no-console
    console.log(message, messageType, parameters)
  }
}

/**
 * @param {string} message
 * @param {Error} [error]
 * @param {any} [parameters]
 */
export function showErrorMessage(message, error = null, parameters) {
  if (Portal.settings.common.showErrorMessage) {
    Portal.settings.common.showErrorMessage(message, error, parameters)
  } else {
    console.error(message, error, parameters)
  }
}

/**
 * Initializes download for given URL
 * Source: https://stackoverflow.com/a/30800715/4729582
 * @param {string} url
 * @param {string} [filename]
 */
export function createDownloadableFile(url, filename = '') {
  const $anchorEl = document.createElement('a')
  $anchorEl.setAttribute('href', url)
  $anchorEl.setAttribute('download', filename)
  $anchorEl.style.display = 'none'
  document.body.appendChild($anchorEl)
  $anchorEl.click()
  document.body.removeChild($anchorEl)
}

/**
 * Creates a file from given data and initializes download
 * @param {*} data
 * @param {string} [filename]
 * @param {string} [mime]
 */
export function createDownloadableFileFromData(data, filename, mime = 'application/json') {
  if (!(_.isString(data) || data instanceof Blob)) {
    try {
      data = JSON.stringify(data, undefined, 2)
    } catch (e) {
      data = String(data)
    }
  }

  data = new Blob([data], { type: mime })
  createDownloadableFile(URL.createObjectURL(data), filename)
}

/**
 * Creates file reader
 * @param {Object} params
 * @param {Function} params.onloadend
 * @returns {FileReader}
 */
export function createFileReader({ onloadend }) {
  const fileReader = new FileReader()
  fileReader.onerror = (event) => {
    showErrorMessage(/** @type {string} */ Portal.i18n.t('common.errors.fileRead'), event)
    fileReader.abort()
  }
  fileReader.onloadend = onloadend
  return fileReader
}

export const fileReadingTypes = Object.freeze({
  arrayBuffer: 'arrayBuffer',
  binaryString: 'binaryString',
  dataURL: 'dataURL',
  text: 'text'
})

/**
 * Creates file reader
 * @param {File} file
 * @param {string} [readingType]
 * @param {string} [encoding] - only for reading as text
 * @returns {Promise<string>}
 */
export function readFile(file, readingType = fileReadingTypes.text, encoding) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = (event) => {
      showErrorMessage(/** @type {string} */ Portal.i18n.t('common.errors.fileRead'), event)
      reader.abort()
      reject()
    }
    reader.onloadend = () => {
      resolve(reader.result)
    }

    switch (readingType) {
      case fileReadingTypes.arrayBuffer:
        reader.readAsArrayBuffer(file)
        break

      case fileReadingTypes.binaryString:
        reader.readAsBinaryString(file)
        break

      case fileReadingTypes.dataURL:
        reader.readAsDataURL(file)
        break

      case fileReadingTypes.text:
        reader.readAsText(file, encoding)
        break
    }
  })
}

/**
 * Opens page with given URL in new tab
 * @param {string} url
 * Doesn't open data URLs in Google Chrome during its restrictions
 */
export function openURLInNewTab(url) {
  const $anchorEl = document.createElement('a')
  $anchorEl.target = '_blank'
  $anchorEl.href = url
  $anchorEl.style.display = 'none'
  document.body.appendChild($anchorEl)
  $anchorEl.click()
  document.body.removeChild($anchorEl)
}

/**
 * Opens image in the new tab
 * @param {string} imageSrc
 * @param {Function} [onload]
 */
export function openImageInNewTab(imageSrc, onload = null) {
  const popup = window.open()
  const $imageEl = popup.document.createElement('img')
  $imageEl.src = imageSrc
  $imageEl.onload = onload
  popup.document.body.appendChild($imageEl)
}

// TODO: try to use openImageInNewTab()
/**
 * Prints the given image by its src
 * @param {string} imageSrc
 */
export function printImageBySrc(imageSrc) {
  const popup = window.open()
  const $imageEl = popup.document.createElement('img')
  $imageEl.src = imageSrc
  $imageEl.onload = function () {
    popup.focus() // required for IE
    popup.print()
    popup.close()
  }
  popup.document.body.appendChild($imageEl)
}

/**
 * Prints the given image
 * @param {string|File|Blob} image
 */
export function printImage(image) {
  if (image instanceof Blob) {
    printImageBySrc(URL.createObjectURL(image))
  } else if (image instanceof File) {
    const onloadend = (event) => {
      printImageBySrc(event.target.result)
    }
    const reader = createFileReader({ onloadend })
    reader.readAsDataURL(image)
  } else {
    printImageBySrc(image)
  }
}

/**
 * Returns name and extension of given file name
 * @param {string} fileName
 * @returns {{name: string|null, extension: string|null}}
 */
export function parseFileName(fileName) {
  let name = null
  let extension = null

  if (fileName.startsWith('.')) {
    extension = fileName.slice(1)
  } else if (fileName) {
    const extensionDelimiterIndex = fileName.lastIndexOf('.')

    if (extensionDelimiterIndex === -1) {
      name = fileName
    } else {
      name = fileName.slice(0, extensionDelimiterIndex)
      extension = fileName.slice(extensionDelimiterIndex + 1)
    }
  }

  return { name, extension }
}

export const bytesSuffixes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

/**
 * Returns file size in human-readable format
 * @param {number} fileSize
 * @param {string[]} [suffixes]
 * @param {number} [divider]
 * @returns {string}
 */
export function formatFileSize(fileSize, suffixes = bytesSuffixes, divider = 1000) {
  // TODO:
  // 1. add ability to set precision from arguments
  // 2. consider if there isn't suffix of needed dimension
  let formattedSize = fileSize
  let divisionsCount = 0

  while (formattedSize >= divider) {
    formattedSize /= divider
    divisionsCount += 1
  }

  formattedSize = _.round(formattedSize, 2)

  return `${formattedSize} ${suffixes[divisionsCount]}`
}

/**
 * Checks if MIME type meets given pattern
 * @param {string} pattern
 * @param {string} value
 * @returns {boolean}
 */
export function checkMIMEType(pattern, value) {
  // TODO: MIME type may have parameters (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types)
  const [patternType, patternSubtype] = pattern.split('/')
  const strictSubtype = patternSubtype !== '*'

  if (strictSubtype) {
    return pattern === value
  }

  const [valueType] = value.split('/')
  return valueType === patternType
}

/**
 * Prepares data to send it in the 'application/x-www-form-urlencoded' format
 * @param {Object} data
 * @returns {URLSearchParams}
 */
export function createEncodedData(data) {
  return Object.entries(data).reduce((encodedParams, [key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => encodedParams.append(key, item))
    } else {
      encodedParams.append(key, value)
    }
    return encodedParams
  }, new URLSearchParams())
}

/**
 * Prepares data to send it in the 'multipart/form-data' format
 * @param {Object} data
 * @returns {FormData}
 */
export function createFormData(data) {
  return Object.entries(data).reduce((acc, [key, value]) => {
    // skip undefined values
    if (value === undefined) {
      return acc
    } else if (value instanceof File) {
      acc.append(key, value, value.name)
    } else if (Array.isArray(value) || _.isPlainObject(value)) {
      acc.append(key, JSON.stringify(value))
    }
    // try to convert into JSON representation of value
    else if (_.isFunction(value?.toJSON)) {
      acc.append(key, value.toJSON())
    } else {
      // Blob will be set as is
      // otherwise value will be converted into string
      acc.append(key, value)
    }
    return acc
  }, new FormData())
}

/**
 * Prepares data to use it in query string of HTTP GET request
 * @param {Object} data
 * @returns {string}
 */
export function createQueryString(data) {
  return Object.entries(data)
    .reduce((acc, [key, value]) => {
      if (value === undefined) {
        return acc
      }

      if (Array.isArray(value) || _.isPlainObject(value)) {
        value = JSON.stringify(value)
      }
      // try to convert into JSON representation of value
      else if (_.isFunction(value?.toJSON)) {
        acc.append(key, value.toJSON())
      } else {
        value = String(value)
      }

      acc.push(`${key}=${encodeURIComponent(value)}`)
      return acc
    }, [])
    .join('&')
}

/**
 * Checks WebGL support in browser
 * Source: https://github.com/mrdoob/three.js/blob/master/examples/js/Detector.js#L13
 * @returns {boolean}
 */
export function checkWebGLSupport() {
  try {
    const canvas = document.createElement('canvas')
    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
  } catch (e) {
    return false
  }
}

import _ from 'lodash'
import i18n from '@/i18n'

export function formatTextInputValue(value) {
  if (_.isString(value)) {
    value = value.trim()
  }

  const coalesce = value === '' || _.isUndefined(value)
  return coalesce ? null : value
}

export function requiredPropertyRule(value) {
  return !!value || i18n.t('common.errors.propertyIsRequired')
}

// source: https://stackoverflow.com/a/32686261/4729582
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u
export function emailRule(value) {
  return !value || emailRegex.test(value) || 'Неверный формат почты'
}

const ogrnRegex = /^\d{13}$/u
export function ogrnRule(value) {
  return !value || ogrnRegex.test(value) || 'Неверный формат ОГРН'
}

const innRegex = /^\d{10}$|^\d{12}$/u
export function innRule(value) {
  return !value || innRegex.test(value) || 'Неверный формат ИНН'
}

import { apiHelper } from '@/api/apiHelper'

export const rts = {
  login({ authorization_code }) {
    return apiHelper.post('/backend/rts/login', {
      data: { authorization_code }
    })
  },

  bind_user({ authorization_code }) {
    return apiHelper.post('/backend/rts/bind_user', {
      data: { authorization_code }
    })
  },

  unbind_user() {
    return apiHelper.post('/backend/rts/unbind_user')
  }
}

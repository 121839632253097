import _ from 'lodash'
import Portal from 'geoportal/src/portal'
import { apiHelper } from '@/api/apiHelper'

// pick only public settings for the sake of security:
// in case if an admin logged out and the less privileged user logged in,
// the latter mustn't be able to read stored non-public settings
function storePublicSettings(settings) {
  function pickSettings(representativeValue, sourceValue) {
    if (_.isPlainObject(representativeValue)) {
      const picked = _.pick(sourceValue, Object.keys(representativeValue))
      return _.mapValues(picked, (value, key) => pickSettings(representativeValue[key], value))
    } else {
      return sourceValue
    }
  }

  const pickedSettings = pickSettings(Portal.store.get('portalSettings'), settings)
  Portal.store.set('portalSettings', pickedSettings)
}

export const settings = {
  get_public_settings() {
    return apiHelper.post('/backend/settings/get_public_settings')
  },

  async get_settings() {
    const result = await apiHelper.post('/backend/settings/get_settings')
    storePublicSettings(result)
    return result
  },

  async update_settings(data) {
    const result = await apiHelper.post('/backend/settings/update_settings', {
      data
    })
    storePublicSettings(result)
    return result
  }
}

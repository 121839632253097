import Feature from 'ol/Feature'
import Polygon from 'ol/geom/Polygon'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import OSMSource from 'ol/source/OSM'
import Collection from 'ol/Collection'
import GraticuleLayer from 'ol/layer/Graticule'
// import { openImageInNewTab } from 'geoportal/src/utils/common'
import { fromLonLat } from 'geoportal/src/utils/geo'

// TODO: beatify
// TODO: split into the two functions: to create map and to create base64-encoded image from map
// TODO: consider if tiles weren't loaded (wonder if the 'rendercomplete' event will be dispatched)
export async function generatePlotImage(coordinates) {
  const mapTarget = document.createElement('div')
  mapTarget.style.width = '400px'
  mapTarget.style.height = '300px'
  mapTarget.style.position = 'absolute'
  mapTarget.style.left = '-10000px'
  document.body.appendChild(mapTarget)

  coordinates = [coordinates[0].map(fromLonLat)]
  const geometry = new Polygon(coordinates)
  const feature = new Feature({ geometry })

  const map = new Map({
    target: mapTarget,
    view: new View({
      center: [0, 0],
      zoom: 2,
      enableRotation: false
    }),
    layers: [
      new TileLayer({
        source: new OSMSource({
          url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        })
      }),
      new VectorLayer({
        id: 'feature',
        source: new VectorSource({
          features: [feature]
        })
      }),
      new GraticuleLayer({
        showLabels: true,
        zIndex: Infinity
      })
    ],
    controls: new Collection()
  })

  const view = map.getView()
  view.fit(geometry.getExtent(), {
    // 70 is used not to intersect with the graticule labels
    padding: [10, 70, 20, 10]
  })
  view.setZoom(view.getZoom() - 1)

  await new Promise((resolve) => {
    map.once('rendercomplete', resolve)
  })

  // source: https://openlayers.org/en/latest/examples/export-map.html
  const canvas = document.createElement('canvas')
  const size = map.getSize()
  canvas.width = size[0]
  canvas.height = size[1]
  const mapContext = canvas.getContext('2d')

  ;[...mapTarget.querySelectorAll('.ol-layer canvas')].forEach((canvas) => {
    if (canvas.width > 0) {
      const opacity = canvas.parentNode.style.opacity
      mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity)
      const transform = canvas.style.transform
      // Get the transform parameters from the style's transform matrix
      const matrix = transform
        .match(/^matrix\(([^(]*)\)$/u)[1]
        .split(',')
        .map(Number)
      // Apply the transform to the export map context
      CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix)
      mapContext.drawImage(canvas, 0, 0)
    }
  })
  document.body.removeChild(mapTarget)

  // openImageInNewTab(canvas.toDataURL())

  return canvas.toDataURL()
}

import _ from 'lodash'
import Vue from 'vue'
import bus from '@/plugins/bus'
import { MessageType } from '@/enums/messages'

/**
 * Prepares error description from given XMLHttpRequest object
 * @param {XMLHttpRequest} request
 * @returns {string}
 */
function getRequestErrorDescription(request) {
  // WARN: not always status equals 0 if there is no connection
  if (request.status === 0) {
    return /** @type {string} */ Vue.i18n.t('common.errors.noConnection')
  } else {
    return `${request.status} ${request.statusText}`
  }
}

/**
 * Prepares error description
 * @param {string} messageText
 * @param {*} [error]
 * @returns {string}
 */
function composeErrorMessage(messageText, error = null) {
  if (error) {
    const errors = []

    // axios response
    // TODO: these fields not necessarily should be present: https://github.com/axios/axios#handling-errors
    if ('request' in error && 'response' in error) {
      errors.push(getRequestErrorDescription(error.request))

      const errorMessage = _.get(error.response, ['data', 'error', 'message'])
      errorMessage && errors.push(errorMessage)
    }

    // XMLHttpRequest
    else if ('status' in error && 'statusText' in error) {
      errors.push(getRequestErrorDescription(error))
    }

    // JavaScript Error
    else if ('message' in error) {
      errors.push(error.message)
    }

    if (errors.length) {
      messageText += `: ${errors.join('. ')}`
    }
  }

  return messageText
}

export function showMessage(messageText, messageType = MessageType.DEFAULT, parameters = {}) {
  bus.emit('messagesQueue/showMessage', { messageText, messageType, parameters })
}

export function showErrorMessage(messageText, error = null, parameters = {}) {
  messageText = composeErrorMessage(messageText, error)
  bus.emit('messagesQueue/showMessage', { messageText, messageType: MessageType.ERROR, parameters })
}

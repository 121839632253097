import { apiHelper } from '@/api/apiHelper'
import { createFormData } from 'geoportal/src/utils/common'

export const banners = {
  get_banners({ type }) {
    return apiHelper.post('/backend/banners/get_banners', {
      data: { type }
    })
  },

  create_banner({ type, file, url }) {
    return apiHelper.post('/backend/banners/create_banner', {
      data: createFormData({ type, file, url })
    })
  },

  delete_banner({ id }) {
    return apiHelper.post('/backend/banners/delete_banner', {
      data: { id }
    })
  },

  update_banner({ id, url }) {
    return apiHelper.post('/backend/banners/update_banner', {
      data: { id, url }
    })
  },

  get_banner_places() {
    return apiHelper.post('/backend/banners/get_banner_places')
  },

  set_banner_places(data) {
    return apiHelper.post('/backend/banners/set_banner_places', {
      data
    })
  }
}

export class NotImplementedError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotImplementedError'
  }
}

export class RequestError extends Error {
  constructor(message, requestError, clientType) {
    super(message)
    this.name = 'RequestError'
    this.stack = requestError.stack

    this.original = requestError
    this.client = clientType
  }

  static wrapAxiosError(requestError) {
    return new RequestError(requestError.message, requestError, 'axios')
  }
}

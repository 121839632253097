import _ from 'lodash'
import defaultSettings from 'geoportal/src/settings'
import { getStoredValueKey } from '@/utils/statePreservation'
import { showMessage, showErrorMessage } from '@/utils/messages'

const settingsConfig = _.merge({}, defaultSettings, {
  urls: {
    geoServer: '/api/geoserver'
  },

  localization: {
    fallbackLocale: false,
    localStorageKey: getStoredValueKey('locale')
  },

  common: {
    showMessage,
    showErrorMessage
  }
})
settingsConfig.localization.availableLocales = [process.env.VUE_APP_I18N_LOCALE]

export default settingsConfig

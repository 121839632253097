import _ from 'lodash'
import Vue from 'vue'
import { showErrorMessage } from 'geoportal/src/utils/common'
import { RequestError } from 'geoportal/src/utils/errors'
import Portal from 'geoportal/src/portal'
import { installGeoportal } from '@/plugins/geoportal'
import bus from '@/plugins/bus'
import vuetify from '@/plugins/vuetify'
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'
import '@/components'
import { UiService } from '@/services/UiService'
import App from '@/App.vue'
import '@/styles/index.scss'

// TODO: improve
function showRequestErrorMessage(requestError) {
  const error = requestError.original

  if (_.isNil(error.response)) {
    showErrorMessage(Portal.i18n.t('common.errors.noConnection'))
    return
  }

  const { data } = error.response
  if (data?.message) {
    // assumes that data of response has format:
    // { code: string, data: Object, message: string }
    showErrorMessage(data.message)
  } else {
    showErrorMessage(Portal.i18n.t('common.errors.request'), error)
  }
}

installGeoportal()

// TODO: replace with: VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)
function isNavigationDuplicatedError(error) {
  return error.name === 'NavigationDuplicated'
}

// handlers of requests errors
// do not handle NavigationDuplicated errors here because it would make debugging a pain
Vue.config.errorHandler = (error) => {
  if (error instanceof RequestError) {
    showRequestErrorMessage(error)
    return
  }

  throw error
}
// required because Vue doesn't handle errors in async functions
window.addEventListener('unhandledrejection', (event) => {
  const error = event.reason

  if (error instanceof RequestError) {
    showRequestErrorMessage(error)
    event.preventDefault()
  } else if (isNavigationDuplicatedError(error)) {
    event.preventDefault()
  }
})

Vue.config.productionTip = false

UiService.init()

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
})
bus.emit('app/created')

app.$mount('#app')
bus.emit('app/mounted')

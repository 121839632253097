import { apiHelper } from '@/api/apiHelper'

export const access = {
  login({ login, password }) {
    return apiHelper.post('/backend/access/login', {
      data: { login, password }
    })
  },

  logout() {
    return apiHelper.post('/backend/access/logout')
  }
}

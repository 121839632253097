<template>
  <v-btn class="text-btn" text :ripple="false" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'TextBtn',
    inheritAttrs: false
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .v-btn.text-btn {
    height: initial;
    min-width: initial;
    padding: initial;
    font-size: unset;
    line-height: unset;
    color: map-get($theme-colors, 'anchor');
    font-weight: initial;
    letter-spacing: initial;
    text-decoration: initial;
    text-transform: initial;
    align-items: initial;
    justify-content: initial;
    text-align: initial;
    flex: initial;
    display: initial;

    &:hover::before,
    &:focus::before {
      opacity: 0;
    }

    &::v-deep .v-btn__content {
      align-items: initial;
      flex: initial;
      display: initial;
      line-height: inherit;
    }

    &:hover {
      color: map-deep-get($material-theme, 'text', 'link-hover');
    }
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import { make } from 'vuex-pathify'
import api from '@/api'
import pathify from './pathify'
import mapModule from './modules/map'
import layersManagementModule from './modules/layersManagement'
import bannersManagementModule from './modules/bannersManagement'

Vue.use(Vuex)

function getState() {
  return {
    portalSettings: null
  }
}
const initialState = getState()

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [pathify.plugin],

  modules: {
    map: mapModule,
    layersManagement: layersManagementModule,
    bannersManagement: bannersManagementModule
  },

  state: initialState,

  mutations: {
    ...make.mutations(initialState)
  },

  actions: {
    async fetchPortalSettings({ commit }) {
      const settings = await api.backend.settings.get_public_settings()
      commit('setPortalSettings', settings)
    }
  }
})

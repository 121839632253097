import _ from 'lodash'
import proj4 from 'proj4'
import kinks from '@turf/kinks'

/** @typedef {[number, number]} PointCoordinate */

export const EPSG_3857 = 'EPSG:3857'
export const EPSG_4326 = 'EPSG:4326'

/**
 * Transforms an angle in degrees to radians
 * @param {number} angle
 * @returns {number}
 */
export function degreesToRadians(angle) {
  return (angle / 180) * Math.PI
}

/**
 * Transforms an angle in radians to degrees
 * @param {number} angle
 * @returns {number}
 */
export function radiansToDegrees(angle) {
  return (angle * 180) / Math.PI
}

/**
 * Transforms a coordinate according to the EPSG:4326 boundaries
 * @param {coordinate} PointCoordinate
 * @returns {PointCoordinate}
 */
export function transformCoordinateToEpsg4326([longitude, latitude]) {
  // wrap longitude
  longitude %= 360
  const absoluteLongitude = Math.abs(longitude)
  if (absoluteLongitude > 180) {
    const signFactor = longitude >= 0 ? -1 : 1 // toggle sign
    longitude = (360 - absoluteLongitude) * signFactor
  }
  return [longitude, latitude]
}

/**
 * Calculates the distance between two points
 * @param {PointCoordinate} p1
 * @param {PointCoordinate} p2
 * @returns {number}
 */
export function calculatePointsDistance(p1, p2) {
  // l = sqrt((x2 - x1)^2 + (y2 - y1)^2)
  return Math.sqrt((p2[0] - p1[0]) ** 2 + (p2[1] - p1[1]) ** 2)
}

/**
 * @param {PointCoordinate} p1
 * @param {PointCoordinate} p2
 * @param {number} eps
 * @returns {boolean}
 */
export function arePointsClose(p1, p2, eps = 1e-9) {
  return calculatePointsDistance(p1, p2) <= eps
}

/**
 * Returns distance between a point and a line passing through the points
 * Source: https://ru.wikipedia.org/wiki/Расстояние_от_точки_до_прямой_на_плоскости#Прямая_задана_двумя_точками
 * @param {PointCoordinate} firstLinePoint
 * @param {PointCoordinate} secondLinePoint
 * @param {PointCoordinate} point
 * @returns {number}
 */
export function getDistanceToLineThroughPoints(firstLinePoint, secondLinePoint, point) {
  const [x1, y1] = firstLinePoint
  const [x2, y2] = secondLinePoint
  const [x0, y0] = point
  return Math.abs((y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1) / Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2)
}

/**
 * Returns the nearest the point onto a line passing through the points for given point
 * @param {PointCoordinate} firstLinePoint
 * @param {PointCoordinate} secondLinePoint
 * @param {PointCoordinate} point
 * @returns {PointCoordinate}
 */
export function getNearestPointOfLineSegmentThroughPoints(firstLinePoint, secondLinePoint, point) {
  const [x1, y1] = firstLinePoint
  const [x2, y2] = secondLinePoint
  const [x0, y0] = point
  let c

  if (_.isEqual(firstLinePoint, secondLinePoint)) {
    c = 0
  } else {
    const v = [x2 - x1, y2 - y1] // p2 - p1
    const w = [x0 - x1, y0 - y1] // p0 - p1

    // c = (w * v) / (v * v)
    const wv = v[0] * w[0] + v[1] * w[1]
    const vv = v[0] * v[0] + v[1] * v[1]
    c = wv / vv
  }

  c = _.clamp(c, 0, 1)

  // point = (1 - c) * p1 + c * p2
  const c2 = 1 - c
  const a = [x1 * c2, y1 * c2]
  const b = [x2 * c, y2 * c]
  return [a[0] + b[0], a[1] + b[1]]
}

/**
 * Returns distance between a point and a line segment passing through the points
 * @param {PointCoordinate} firstLinePoint
 * @param {PointCoordinate} secondLinePoint
 * @param {PointCoordinate} point
 * @returns {number}
 */
export function getDistanceToLineSegmentThroughPoints(firstLinePoint, secondLinePoint, point) {
  const nearestPoint = getNearestPointOfLineSegmentThroughPoints(firstLinePoint, secondLinePoint, point)
  return calculatePointsDistance(point, nearestPoint)
}

/**
 * Returns projection of a point onto a line passing through the points
 * Sources:
 *  - https://matworld.ru/analytic-geometry/proekcija-tochki-na-prjamuju.php
 *  - http://www.cleverstudents.ru/line_and_plane/line_passes_through_2_points.html
 * @param {PointCoordinate} firstLinePoint
 * @param {PointCoordinate} secondLinePoint
 * @param {PointCoordinate} point
 * @returns {PointCoordinate}
 */
export function getProjectionOfPointOnLineThroughPoints(firstLinePoint, secondLinePoint, point) {
  const [x1, y1] = firstLinePoint
  const [x2, y2] = secondLinePoint
  const [x0, y0] = point

  const m = x2 - x1
  const p = y2 - y1

  const t = (m * x0 + p * y0 - m * x1 - p * y1) / (m ** 2 + p ** 2)

  const x = m * t + x1
  const y = p * t + y1

  return [x, y]
}

/**
 * @param {PointCoordinate} firstLinePoint
 * @param {PointCoordinate} secondLinePoint
 * @param {PointCoordinate} point
 * @param {number} [eps]
 * @returns {boolean}
 */
export function pointLiesOnLine(firstLinePoint, secondLinePoint, point, eps = 1e-9) {
  const [x1, y1] = firstLinePoint
  const [x2, y2] = secondLinePoint
  const [x, y] = point

  if ((x === x1 && y === y1) || (x === x2 && y === y2)) {
    return true
  }

  return Math.abs((x - x1) / (y - y1) - (x - x2) / (y - y2)) <= eps
}

/**
 * Returns direction (angle) of vector
 * @param {PointCoordinate} p1
 * @param {PointCoordinate} p2
 * @returns {number} - angle in radians
 */
export function calculateVectorDirection(p1, p2) {
  const y = p2[1] - p1[1]
  const x = p2[0] - p1[0]
  // considers -0 as well
  if (x === 0 && y === 0) {
    return 0
  }
  return Math.atan2(y, x)
}

/**
 * Calculates the angle between given points
 * @param {PointCoordinate} p1
 * @param {PointCoordinate} p2
 * @param {PointCoordinate} p3
 * @returns {number} - angle in radians
 */
export function calculateAngleBetweenPoints(p1, p2, p3) {
  // the law of cosines
  // C = acos((a^2 + b^2 - c^2) / 2ab)
  const a = calculatePointsDistance(p1, p2)
  const b = calculatePointsDistance(p2, p3)
  const c = calculatePointsDistance(p3, p1)
  return Math.acos((a ** 2 + b ** 2 - c ** 2) / (2 * a * b))
}

const fromLonLatHelper = proj4(EPSG_4326, EPSG_3857)

/**
 * Transforms given coordinates from EPSG:4326 projection to EPSG:3857
 * @param {PointCoordinate} coordinates
 * @returns {PointCoordinate}
 */
export function fromLonLat(coordinates) {
  return fromLonLatHelper.forward(coordinates)
}

const toLonLatHelper = proj4(EPSG_3857, EPSG_4326)

/**
 * Transforms given coordinates from EPSG:3857 projection to EPSG:4326
 * @param {PointCoordinate} coordinates
 * @returns {PointCoordinate}
 */
export function toLonLat(coordinates) {
  return toLonLatHelper.forward(coordinates)
}

/**
 * Returns decimal representation of angle from given degrees, minutes, seconds
 * @param {number} degrees
 * @param {number} minutes
 * @param {number} seconds
 * @returns {number}
 */
export function angleComponentsToDecimal(degrees, minutes, seconds) {
  const signFactor = degrees >= 0 ? 1 : -1
  return signFactor * (Math.abs(degrees) + minutes / 60 + seconds / 3600)
}

/**
 * Returns degrees, minutes, seconds of given angle
 * @param {number} angle
 * @param {number} secondsPrecision
 * @returns {{seconds: number, minutes: number, degrees: number}}
 */
export function decimalAngleToComponents(angle, secondsPrecision = 0) {
  const signFactor = angle >= 0 ? 1 : -1
  angle = Math.abs(angle)
  let degrees = Math.trunc(angle)
  let minutes = Math.trunc((angle - degrees) * 60)
  let seconds = _.round((angle - degrees - minutes / 60) * 3600, secondsPrecision)

  if (seconds === 60) {
    seconds = 0
    minutes += 1
  }

  if (minutes === 60) {
    minutes = 0
    degrees += 1
  }

  degrees *= signFactor
  return { degrees, minutes, seconds }
}

/**
 * Checks if feature is self-intersecting
 * Returns false if feature is self-intersecting
 * @param {Object} feature
 * @returns {boolean}
 */
export function checkFeatureSelfIntersection(feature) {
  return !kinks(feature).features.length
}

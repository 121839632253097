import _ from 'lodash'
import commonBase from 'geoportal/src/i18n/modules/common.ru'

export default _.defaultsDeep(
  {
    errors: {
      propertyIsRequired: 'Поле обязательно'
    }
  },
  commonBase
)

import _ from 'lodash'
import Vue from 'vue'

/**
 * Function to conveniently get component by reference
 * Useful for components used with v-for directive: https://vuejs.org/v2/api/#ref
 * @param {Vue} component
 * @param {string} reference
 * @returns {Vue|Element}
 */
export function getRefCmp(component, reference) {
  const cmp = component.$refs[reference]
  return Array.isArray(cmp) ? cmp[0] : cmp
}

/**
 * Calls callback on beforeDestroy hook of given component
 * @param {Vue} component
 * @param {Function} callback
 * @returns {Function} unwatch
 */
export function beforeDestroy(component, callback) {
  component.$once('hook:beforeDestroy', callback)
  return () => component.$off('hook:beforeDestroy', callback)
}

/**
 * @param {Vue} component
 * @param {string[]} props
 * @param {Function} callback
 * @param {Object} [options]
 * @param {number|null} [options.wait]
 * @param {boolean} [options.deep]
 * @param {boolean} [options.immediate]
 * @returns {Function} - unwatcher
 */
export function watchProps(component, props, callback, { wait = null, deep = false, immediate = false } = {}) {
  let unwatchers = null
  let beforeDestroyUnwatcher
  const watcherCb = _.isNil(wait) ? callback : _.debounce(callback, wait)
  unwatchers = props.map((prop) => {
    const watcher = (value, oldValue) => {
      watcherCb(prop, value, oldValue)
    }
    return component.$watch(prop, watcher, { deep, immediate })
  })
  const unwatchAll = () => {
    if (!unwatchers) {
      return
    }
    unwatchers.forEach((unwatch) => unwatch())
    unwatchers = null
  }
  beforeDestroyUnwatcher = beforeDestroy(component, () => {
    unwatchAll()
    beforeDestroyUnwatcher = null
  })
  return () => {
    unwatchAll()
    if (!beforeDestroyUnwatcher) {
      return
    }
    beforeDestroyUnwatcher()
    beforeDestroyUnwatcher = null
  }
}

/**
 * @param {Vue} component
 * @param {Object.<string, string>} mapping
 * @param {Object} [options]
 * @param {boolean} [options.deep]
 * @param {boolean} [options.immediate]
 * @param {Function} [options.predicate] - checks whether change should be applied
 * @param {Function} [options.formatter] - formats new value
 * @returns {Function} - unwatcher
 */
export function mapPropertiesChanges(
  component,
  mapping,
  { deep = false, immediate = false, predicate = _.stubTrue, formatter = _.identity } = {}
) {
  let unwatchers = Object.entries(mapping).map(([propertyFrom, propertyTo]) => {
    const watcher = (value) => {
      if (!predicate(propertyFrom, value, propertyTo)) {
        return
      }
      _.set(component, propertyTo, formatter(value))
    }
    return component.$watch(propertyFrom, watcher, { deep, immediate })
  })
  const unwatchAll = () => {
    if (!unwatchers) {
      return
    }
    unwatchers.forEach((unwatch) => unwatch())
    unwatchers = null
  }
  let beforeDestroyUnwatcher = beforeDestroy(component, () => {
    unwatchAll()
    beforeDestroyUnwatcher = null
  })
  return () => {
    unwatchAll()
    if (!beforeDestroyUnwatcher) {
      return
    }
    beforeDestroyUnwatcher()
    beforeDestroyUnwatcher = null
  }
}

/**
 * @param {Object} destination
 * @param {Object} source
 */
function reactiveMerger(destination, source) {
  Object.entries(source).forEach(([key, value]) => {
    if (_.isPlainObject(destination[key]) && _.isPlainObject(value)) {
      reactiveMerger(destination[key], value)
    } else {
      Vue.set(destination, key, value)
    }
  })
}

/**
 * Merge objects preserving reactivity
 * @param {Object} destination
 * @param {Object} source
 * @returns {Object}
 */
export function deepReactiveMerge(destination, source) {
  if (!_.isPlainObject(destination) || !_.isPlainObject(source)) {
    throw destination
  }
  reactiveMerger(destination, source)
  return destination
}

export const injectedPropertyPrefix = '$'

/**
 * Injects object into Vue prototype
 * @param {VueConstructor} Vue
 * @param {PropertyKey} key
 * @param {*} object
 */
export function injectIntoPrototype(Vue, key, object) {
  const componentKey = injectedPropertyPrefix + key

  if (componentKey in Vue.prototype) {
    throw new Error(`Property ${componentKey} is already used in Vue`)
  }

  Object.defineProperty(Vue.prototype, componentKey, {
    get() {
      return object
    }
  })
}

/**
 * Injects object into Vue constructor and into its prototype
 * Object will injected in prototype by given key with special prefix
 * @param {VueConstructor} Vue
 * @param {PropertyKey} key
 * @param {*} object
 */
export function inject(Vue, key, object) {
  if (key in Vue) {
    throw new Error(`Property ${key} is already used in Vue constructor`)
  }

  Vue[key] = object
  injectIntoPrototype(Vue, key, object)
}

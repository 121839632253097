// default settings which should be merged with the setting of project
import { DateTimeType } from './resources/dateTimeSettings'

export default {
  urls: {},

  // Unicode Tokens
  dateTimeTypesToDisplayFormats: {
    [DateTimeType.DATETIME]: 'dd.MM.yyyy HH:mm:ss',
    [DateTimeType.DATE]: 'dd.MM.yyyy',
    [DateTimeType.YEAR]: 'yyyy',
    [DateTimeType.MONTH]: 'MM.yyyy',
    [DateTimeType.WEEK]: 'ww',
    [DateTimeType.TIME]: 'HH:mm:ss'
  },

  localization: {
    availableLocales: ['ru', 'en'],
    defaultLocale: process.env.VUE_APP_I18N_LOCALE || 'ru',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    localStorageKey: 'locale'
  },

  common: {
    showMessage: null,
    showErrorMessage: null
  }
}

import _ from 'lodash'
import axios from 'axios'

import { showErrorMessage } from '../utils/common'
import { RequestError } from '../utils/errors'
import Portal from '../portal'

const defaultConfig = {
  method: 'get',
  responseType: 'json'
}
const defaultAdditionalConfig = {
  validateResponse: true,
  formatResponse: true
}

export function setClientTimeZoneHeader(config) {
  try {
    // eslint-disable-next-line new-cap
    config.headers['X-The-Timezone-IANA'] = Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch {}

  if (Portal.i18n?.locale) {
    config.headers['Content-Language'] = Portal.i18n.locale
  }

  return config
}

// WARNING: legacy decorator (stage 1)
// NOTE: legacy decorator are enabled by default in @vue/cli-plugin-babel/preset
export function provideCancelToken() {
  const sources = new Set()

  return function (target, name, descriptor) {
    const original = descriptor.value
    descriptor.value = async function (...args) {
      if (sources.size) {
        ;[...sources].forEach((source) => source.cancel())
        sources.clear()
      }

      const cancelTokenSource = axios.CancelToken.source()
      sources.add(cancelTokenSource)

      const cancelToken = cancelTokenSource.token

      try {
        return await original.call(this, cancelToken, ...args)
      } finally {
        sources.delete(cancelTokenSource)
      }
    }
  }
}

export function defaultRequestConfigInterceptor(config) {
  // ensure that always an empty object will be passed
  if (config.method !== 'get' && config.responseType === 'json' && !config.data) {
    config.data = {}
  }

  setClientTimeZoneHeader(config)

  return config
}

// derived from axios/lib/core/createError.js
export function createAxiosLikeErrorFromResponse(response) {
  const { config, request, status, code } = response

  const message = `Request failed with status code ${status}`

  const error = new Error(message)
  error.config = config
  if (code) {
    error.code = code
  }
  error.request = request
  error.response = response
  return error
}

export function defaultResponseValidator(response) {
  if (response.config.responseType === 'json' && 'error' in response.data) {
    throw RequestError.wrapAxiosError(createAxiosLikeErrorFromResponse(response))
  }
}

export function defaultResponseFormatter(response) {
  return response.config.responseType === 'json' ? response.data?.result : response.data
}

export function simpleResponseFormatter(response) {
  return response.data
}

/**
 * @param {RequestError} requestError
 */
export function showRequestErrorMessage(requestError) {
  const error = requestError.original

  if (_.isNil(error.response)) {
    showErrorMessage(Portal.i18n.t('common.errors.noConnection'))
    return
  }

  const { data } = error.response
  let message = Portal.i18n.t('common.errors.request')

  if (!data?.error) {
    showErrorMessage(message, error)
    return
  }

  // assumes that data of response has format:
  // { error: { code: string, message: string, status_code: number } }
  message = `${message}: ${data.error.message}`
  showErrorMessage(message)
}

export function createAPIHelper(settings = {}) {
  const client = axios.create(settings.client)

  // resolve request interceptor of helper

  let requestInterceptor

  if (settings.requestInterceptor === false) {
    requestInterceptor = null
  } else if (_.isFunction(settings.requestInterceptor)) {
    requestInterceptor = settings.requestInterceptor
  } else {
    requestInterceptor = defaultRequestConfigInterceptor
  }

  requestInterceptor && client.interceptors.request.use(requestInterceptor)

  // resolve response validator of helper

  let helperResponseValidator

  if (settings.responseValidator === false) {
    helperResponseValidator = _.noop
  } else if (_.isFunction(settings.responseValidator)) {
    helperResponseValidator = settings.responseValidator
  } else {
    helperResponseValidator = defaultResponseValidator
  }

  // resolve response formatter of helper

  let helperResponseFormatter

  if (settings.responseFormatter === false) {
    helperResponseFormatter = _.identity
  } else if (_.isFunction(settings.responseFormatter)) {
    helperResponseFormatter = settings.responseFormatter
  } else {
    helperResponseFormatter = defaultResponseFormatter
  }

  /**
   * @param {AxiosRequestConfig} config
   * @param {{validateResponse: boolean, formatResponse: boolean}} [additionalConfig]
   * @returns {Promise<AxiosResponse<*>|*>}
   */
  async function request(config = {}, additionalConfig = {}) {
    config = { ...defaultConfig, ...config }
    const { validateResponse, formatResponse } = { ...defaultAdditionalConfig, ...additionalConfig }

    let response
    try {
      response = await client.request(config)
    } catch (error) {
      // check if the request was made (https://github.com/axios/axios#handling-errors)
      if (error.request) {
        throw RequestError.wrapAxiosError(error)
      }

      if (!axios.isCancel(error)) {
        showErrorMessage(Portal.i18n.t('common.errors.request'), error)
      }

      throw error
    }

    // resolve response validator

    if (validateResponse === true) {
      helperResponseValidator(response)
    } else if (_.isFunction(validateResponse)) {
      validateResponse(response)
    }

    // resolve response formatter

    if (formatResponse === true) {
      return helperResponseFormatter(response)
    } else if (_.isFunction(formatResponse)) {
      return formatResponse(response)
    } else {
      return response
    }
  }

  function createMethodAlias(method) {
    return (url, config = {}, additionalConfig = {}) => request({ ...config, method, url }, additionalConfig)
  }

  return {
    client,
    request,
    get: createMethodAlias('get'),
    post: createMethodAlias('post'),
    put: createMethodAlias('put'),
    delete: createMethodAlias('delete'),
    head: createMethodAlias('head'),
    options: createMethodAlias('options'),
    patch: createMethodAlias('patch')
  }
}

import _ from 'lodash'
import tinycolor from 'tinycolor2'
import { PlotStatus } from '@/enums/aquarius'
import vuetify from '@/plugins/vuetify'

export const LAYER_NAMESPACE = 'aquarius'

export const plotPropertiesPrecision = {
  coordinates: 6,
  area: 4,
  money: 2,
  weight: 3
}

export const plotStatusesToOrder = _.mapValues(
  _.invert([
    PlotStatus.DRAFT,
    PlotStatus.UNKNOWN,
    PlotStatus.SENT_TO_TRADING_PLATFORM,
    PlotStatus.REJECTED_BY_TIMEOUT,
    PlotStatus.FAR_APPLICATION_FORMATION,
    PlotStatus.FAR_APPLICATION_SENT,
    PlotStatus.FAR_APPLICATION_REJECTED,
    PlotStatus.AUCTION_PREPARATION,
    PlotStatus.AUCTION_EXECUTION,
    PlotStatus.AUCTION_COMPLETED,
    PlotStatus.AUCTION_COMPLETED,
    PlotStatus.DISBANDED,
    PlotStatus.IN_USE
  ]),
  Number
)

// draft is not listed, because it's availability depends on whether the user is logged in
// export const visiblePlotStatuses = [
//   PlotStatus.SENT_TO_TRADING_PLATFORM,
//   PlotStatus.FAR_APPLICATION_FORMATION,
//   PlotStatus.FAR_APPLICATION_SENT,
//   PlotStatus.AUCTION_EXECUTION,
//   PlotStatus.AUCTION_COMPLETED,
//   PlotStatus.IN_USE,
//   PlotStatus.AUCTION_PREPARATION
// ]

export const plotStatusGroups = [
  {
    text: 'Черновик',
    statuses: [PlotStatus.DRAFT],
    color: '#666666',
    backgroundColor: 'rgba(134, 134, 134, 0.1)'
  },
  {
    text: 'Заявление подано',
    statuses: [
      PlotStatus.SENT_TO_TRADING_PLATFORM,
      PlotStatus.FAR_APPLICATION_FORMATION,
      PlotStatus.FAR_APPLICATION_SENT,
      PlotStatus.AUCTION_PREPARATION
    ],
    color: '#ad9c16',
    backgroundColor: 'rgba(255, 235, 78, 0.13)'
  },
  {
    text: 'Выставлен на аукцион',
    statuses: [PlotStatus.AUCTION_EXECUTION],
    color: vuetify.framework.theme.currentTheme.guideAccent2,
    backgroundColor: 'rgba(255, 152, 29, 0.23)'
  },
  {
    text: 'Заключение договора',
    statuses: [PlotStatus.AUCTION_COMPLETED],
    color: '#ff0202',
    backgroundColor: 'rgba(255, 2, 2, 0.1)'
  },
  {
    text: 'В пользовании',
    statuses: [PlotStatus.IN_USE],
    color: '#1485a5',
    backgroundColor: tinycolor('#1485a5').setAlpha(0.15).toRgbString()
  }
]
export const plotStatusesToGroups = plotStatusGroups.reduce((acc, group) => {
  group.statuses.forEach((status) => {
    acc[status] = group
  })
  return acc
}, {})

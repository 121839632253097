<template>
  <v-form :id="id" ref="form" class="base-form" :class="{ 'base-form_inline': inline }" @submit.prevent="">
    <field
      v-for="field of configuration"
      :key="field.id"
      :ref="`field.${field.id}`"
      :value="value[field.id]"
      :configuration="field"
      :form-id="id"
      :is-form-disabled="disabled"
      @input="handleValueChange(field.id, $event)"
    />

    <slot />
  </v-form>
</template>

<script>
  import _ from 'lodash'
  import Field from '@/components/BaseForm/Field'

  export default {
    name: 'BaseForm',
    components: { Field },

    props: {
      value: {
        type: Object,
        default: () => {}
      },
      configuration: {
        type: Array,
        default: () => []
      },
      id: {
        type: String,
        default: () => _.uniqueId('base-form-')
      },
      disabled: {
        type: Boolean,
        default: false
      },
      inline: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleValueChange(fieldId, fieldValue) {
        const value = { ...this.value, [fieldId]: fieldValue }
        this.$emit('input', value)
      },

      // focuses on the first input
      focus() {
        if (this.disabled) {
          return
        }

        const $form = /** @type {HTMLFormElement} */ this.$el
        const inputs = $form.elements
        if (!inputs.length) {
          return
        }

        // WARN: may not to work with some custom inputs
        //  https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements#Value
        const inputToFocus = [...inputs].find((input) => !(input.disabled || input.readonly))
        inputToFocus && inputToFocus.focus()
      },

      validate() {
        return this.$refs.form.validate()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .base-form {
    display: grid;
    gap: 0 12px;

    &.base-form_inline {
      grid-auto-flow: column;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(auto-fill, minmax(auto, 100px));
    }
  }
</style>

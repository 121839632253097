<template>
  <div class="app-bar" :class="{ 'is-touch-device': isTouchDevice }">
    <v-app-bar class="flex-grow-0" app dense color="white" :elevation="isTouchDevice ? 3 : 0">
      <template v-if="isTouchDevice">
        <v-app-bar-nav-icon @click.stop="isDrawnActive = !isDrawnActive">
          <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>

        <span v-if="$route.meta.titleTr" class="ml-8 text-xl font-weight-medium">
          {{ $t($route.meta.titleTr) }}
        </span>
      </template>

      <template v-else>
        <v-img class="my-1 flex-grow-0" :src="require('@/assets/veb_rf_logo.png')" :height="32" :width="106" contain />

        <v-menu open-on-hover offset-y>
          <template #activator="{ on, attrs }">
            <v-btn class="ml-15" to="/map" plain v-bind="attrs" v-on="on">Интерактивная карта</v-btn>
          </template>

          <v-list>
            <v-list-item :to="{ path: '/map', query: { basin: Basin.FAR_EAST } }" exact>
              <v-list-item-title>Дальневосточный РХБ</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ path: '/map', query: { basin: Basin.NORTH } }" exact>
              <v-list-item-title>Северный РХБ</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu open-on-hover offset-y>
          <template #activator="{ on, attrs }">
            <span class="menu-item ml-3" v-bind="attrs" v-on="on">Информация</span>
          </template>

          <v-list>
            <v-list-item v-for="page of publicPages" :key="page.to" v-bind="page">
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer />

        <template v-if="$auth.ready">
          <v-menu v-if="$auth.authenticated" :min-width="220" open-on-hover offset-y>
            <template #activator="{ on, attrs }">
              <v-btn class="ml-3" :to="isEmailConfirmed ? '/user/profile' : undefined" plain v-bind="attrs" v-on="on">
                {{ $auth.user.account_type === AccountType.INVESTOR ? 'Личный кабинет' : 'Панель управления' }}
              </v-btn>
            </template>

            <div
              v-if="!isEmailConfirmed"
              class="email-confirmation-warning pa-3 pb-2 d-flex justify-center-center white"
            >
              <v-sheet class="pa-3 font-weight-bold" rounded color="error" dark>
                Для работы с данным разделом необходимо
                <router-link class="text--white" :to="confirmEmailRoutePath">подтвердить почту</router-link>
              </v-sheet>
            </div>

            <v-list>
              <v-list-item
                v-for="(item, i) of userMenuItems"
                :key="i"
                :disabled="item.disabled"
                :to="item.to"
                :exact="item.exact"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-if="$auth.authenticated" class="ml-auto" plain @click="$auth.logout()">Выйти</v-btn>
          <v-btn v-else :to="{ path: '/login', query: { redirect: $route.fullPath } }" plain>Войти</v-btn>
        </template>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-if="isTouchDevice" v-model="isDrawnActive" temporary fixed>
      <template #prepend>
        <v-img class="d-block ma-3" :src="require('@/assets/veb_rf_logo.png')" :height="32" :width="106" contain />
      </template>

      <v-list dense>
        <v-list-item to="/map">
          <v-list-item-title>Интерактивная карта</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item v-for="page of publicPages" :key="page.to" v-bind="page">
          <v-list-item-title>{{ page.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-if="$auth.ready">
        <v-divider />

        <v-list dense>
          <v-list-item
            v-for="(item, i) of userMenuItems"
            :key="i"
            :disabled="item.disabled"
            :to="item.to"
            :exact="item.exact"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-if="$auth.ready" #append>
        <div class="d-flex pa-2">
          <v-btn v-if="$auth.authenticated" class="flex-grow-1" small depressed @click="$auth.logout()">Выйти</v-btn>

          <v-btn
            v-else
            class="flex-grow-1"
            :to="{ path: '/login', query: { redirect: $route.fullPath } }"
            small
            depressed
          >
            Войти
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { AccountType, Basin } from '@/enums/aquarius'
  import { checkIfTouchDevice } from '@/utils/common'
  import { RegistrationStep } from '@/views/UserRegistrationView/userRegistration'

  const publicPages = [
    // TEMP
    // {
    //   title: 'О системе',
    //   href: process.env.VUE_APP_ABOUT_SYSTEM_PAGE_URL,
    //   target: '_blank',
    //   rel: 'noreferrer noopener'
    // },
    { title: 'Список участков', to: '/plots-list', rel: 'nofollow' },
    { title: 'Частые вопросы', to: '/faq' },
    // { title: 'Информация', to: '/portal-description' },
    { title: 'Состояние системы', to: '/monitoring' },
    { title: 'Обратная связь', to: '/feedback', rel: 'nofollow' }
  ]

  export default {
    name: 'TheAppBar',

    data() {
      return {
        Basin,
        AccountType,

        publicPages,

        isDrawnActive: false
      }
    },

    computed: {
      isAdmin() {
        return this.$auth.user.account_type === AccountType.ADMIN
      },

      isEmailConfirmed() {
        return !this.$auth.user.pending_email_confirmation
      },

      confirmEmailRoutePath() {
        return `/registration/${RegistrationStep.CONFIRM_EMAIL}`
      },

      userMenuItems() {
        const items = [{ title: 'Профиль', to: '/user/profile', exact: true, disabled: !this.isEmailConfirmed }]

        const layersManagement = { title: 'Управление слоями', to: '/layers-management' }

        switch (this.$auth.user.account_type) {
          case AccountType.ADMIN:
            items.push(
              { title: 'Организации', to: '/users' },
              layersManagement,
              { title: 'Управление баннерами', to: '/banners-management' },
              { title: 'Настройки мониторинга', to: '/monitoring-settings' },
              { title: 'Настройки РТС-тендер', to: '/rts-settings' },
              { title: 'Настройки системы', to: '/portal-settings' }
            )
            break

          case AccountType.FOIV:
          case AccountType.MO:
            items.push(layersManagement)
            break

          case AccountType.INVESTOR:
            items.push({ title: 'Оповещения', to: '/user/notifications', disabled: !this.isEmailConfirmed })
        }

        return items
      },

      isTouchDevice() {
        return checkIfTouchDevice()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .app-bar {
    .v-app-bar {
      border-bottom: thin solid map-get($grey, 'lighten-2') !important;
    }

    &:not(.is-touch-device) {
      .v-app-bar::v-deep .v-toolbar__content {
        padding: 4px 20px 4px 68px;
      }
    }

    .menu-item {
      // derived from button styles
      padding: 8px 16px;
      font-size: 0.875rem;
      letter-spacing: 0.0892857143em;
      font-weight: 500;
      text-transform: uppercase;
      color: rgba(map-deep-get($material-theme, 'text', 'primary'), 0.62);
      user-select: none;
    }
  }

  .email-confirmation-warning .v-sheet {
    width: 200px;
  }
</style>

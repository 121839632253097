import { apiHelper } from '@/api/apiHelper'

export const plots = {
  get_plots({ filters, sorting } = {}) {
    return apiHelper.post('/backend/plots/get_plots', {
      data: { filters, sorting }
    })
  },

  // get_plot({ id } = {}) {
  //   return apiHelper.post('/backend/plots/get_plot', {
  //     data: { id }
  //   })
  // },

  create_plot({ plot, save }) {
    return apiHelper.post('/backend/plots/create_plot', {
      data: { plot, save }
    })
  },

  delete_plot({ id }) {
    return apiHelper.post('/backend/plots/delete_plot', {
      data: { id }
    })
  },

  send_plot_to_rts({ id }) {
    return apiHelper.post('/backend/plots/send_plot_to_rts', {
      data: { id }
    })
  },

  get_buffer_zones() {
    return apiHelper.post('/backend/plots/get_buffer_zones')
  },

  get_favorite_plots() {
    return apiHelper.post('/backend/plots/get_favorite_plots')
  },

  set_favorite_plot({ id, is_favorite }) {
    return apiHelper.post('/backend/plots/set_favorite_plot', {
      data: { id, is_favorite }
    })
  }
}

import Vue from 'vue'
import BaseGeoportalFrontend from 'geoportal/src/index'
import { injectIntoPrototype } from 'geoportal/src/utils/vue'
import settings from '@/settings'
import bus from './bus'
import i18n from '@/i18n'
import auth from './auth'
import store from '@/store'
import router from '@/router'
import api from '@/api'

function setGeoportalMetadata() {
  window.__geoportal__ = {
    buildTime: process.env.VUE_APP_BUILD_TIME,
    version: process.env.VUE_APP_VERSION,
    commit: process.env.VUE_APP_COMMIT,
    baseFrontend: {
      version: process.env.VUE_APP_BASE_FRONTEND_VERSION,
      commit: process.env.VUE_APP_BASE_FRONTEND_COMMIT
    }
  }
}

export function installGeoportal() {
  injectIntoPrototype(Vue, 'bus', bus)
  injectIntoPrototype(Vue, 'api', api)
  injectIntoPrototype(Vue, 'auth', auth)

  setGeoportalMetadata()

  Vue.use(BaseGeoportalFrontend, {
    settings,
    i18n,
    store,
    router,
    auth
  })
}

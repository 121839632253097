import _ from 'lodash'
import { VTextField, VSelect } from 'vuetify/lib'
import NumberInput from '@/components/NumberInput'
import PasswordInput from '@/components/PasswordInput'

const fieldDefaultProperties = {
  disabled: false,
  required: false
}

export const formFieldPresets = {
  text: {
    cmp: VTextField.options.name,
    bind: {
      // clearable: true,
      dense: true,
      // hideDetails: 'auto',
      filled: true,
      autocomplete: 'off'
    }
  },
  select: {
    cmp: VSelect.options.name,
    bind: {
      // clearable: true,
      dense: true,
      // hideDetails: 'auto',
      filled: true
    }
  },
  number: {
    cmp: NumberInput.name,
    bind: {
      // clearable: true,
      dense: true,
      // hideDetails: 'auto',
      filled: true,
      autocomplete: 'off'
    }
  },
  password: {
    cmp: PasswordInput.name,
    bind: {
      // clearable: true,
      dense: true,
      // hideDetails: 'auto',
      filled: true,
      autocomplete: 'off'
    }
  }
  // checkbox: {
  //   cmp: VCheckbox.options.name,
  //   bind: {
  //     dense: true,
  //     hideDetails: 'auto'
  //   }
  // }
}

export function createFormConfiguration(settings) {
  return settings.map(([fieldConfiguration, fieldPreset = undefined]) =>
    _.defaultsDeep(fieldConfiguration, fieldDefaultProperties, fieldPreset)
  )
}

import { apiHelper } from '@/api/apiHelper'

export const notifications = {
  get_settings() {
    return apiHelper.post('/backend/notifications/get_settings')
  },

  update_settings({ notify_user = undefined, notification_contacts = undefined } = {}) {
    return apiHelper.post('/backend/notifications/update_settings', {
      data: { notify_user, notification_contacts }
    })
  }
}

import _ from 'lodash'

/**
 * @param {string|Array} value
 * @param {number|null} [minLength]
 * @param {number|null} [maxLength]
 * @returns {boolean}
 */
export function validateLength(value, minLength = null, maxLength = null) {
  if (!_.isNil(minLength) && value.length < minLength) {
    return false
  } else if (!_.isNil(maxLength) && value.length > maxLength) {
    return false
  }
  return true
}

/**
 * @param {string} value
 * @returns {boolean}
 */
export function validateURL(value) {
  try {
    // try to parse with builtin parser
    // eslint-disable-next-line no-new
    new URL(value)
  } catch (e) {
    return false
  }
  return true
}

/**
 * @param {string} value
 * @param {boolean} [isPortRequired]
 * @returns {boolean}
 */
export function validateIpPort(value, isPortRequired = true) {
  if (!_.isString(value)) {
    return false
  }

  const [address, port, ...rest] = value.split(':')

  if (rest.length) {
    return false
  }

  if (isPortRequired && !port) {
    return false
  }

  if (port) {
    // check if each character is digit
    if (!/^\d{1,5}$/u.test(port)) {
      return false
    }

    const parsedPort = parseInt(port, 10)
    const check = parsedPort >= 0 && parsedPort <= 65535
    if (!check) {
      return false
    }
  }

  const octets = address.split('.')
  if (octets.length !== 4) {
    return false
  }

  const octetRegexp = /^\d{1,3}$/u
  return octets.every((octet) => {
    if (!octetRegexp.test(octet)) {
      return false
    }

    const parsedOctet = parseInt(octet, 10)
    return parsedOctet >= 0 && parsedOctet <= 255
  })
}

/**
 * @param {string} value
 * @returns {boolean}
 */
export function validatePassword(value) {
  return value.length >= 8 && /[\d]/u.test(value) && value !== value.toLowerCase() && value !== value.toUpperCase()
}

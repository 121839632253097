<template>
  <v-snackbar
    v-if="currentMessage"
    :key="currentMessage.id"
    :value="!!currentMessage"
    :timeout="currentMessage.duration === 0 ? -1 : currentMessage.duration"
    right
    v-bind="currentMessage.bind"
    @input="!$event && queue.shift()"
  >
    {{ currentMessage.messageText }}

    <template v-if="currentMessage.closable" #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="queue.shift()">
        {{ $t('common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { v4 as uuid } from 'uuid'
  import _ from 'lodash'
  import { MessageType } from '@/enums/messages'

  const defaultParameters = {
    duration: 5000,
    closable: true,
    bind: {}
  }

  const messageTypeToColor = {
    [MessageType.DEFAULT]: undefined,
    [MessageType.INFO]: 'info',
    [MessageType.SUCCESS]: 'success',
    [MessageType.WARNING]: 'warning',
    [MessageType.ERROR]: 'error'
  }

  export default {
    name: 'TheMessagesQueue',

    data() {
      return {
        queue: []
      }
    },

    computed: {
      currentMessage() {
        return this.queue.length ? this.queue[0] : null
      }
    },

    created() {
      this.$bus.listen(this, 'messagesQueue/showMessage', this.addMessage)
    },

    methods: {
      addMessage({ messageText, messageType, parameters = {} }) {
        this.queue.push(
          _.merge({}, defaultParameters, parameters, {
            id: uuid(),
            messageText,
            messageType,
            bind: {
              color: messageTypeToColor[messageType]
            }
          })
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-snack {
    top: initial;
    bottom: 3.2rem;
  }
</style>

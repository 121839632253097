import _ from 'lodash'
import Vue from 'vue'
import { make } from 'vuex-pathify'
import Portal from 'geoportal/src/portal'
import { Basin, PlotStatus } from '@/enums/aquarius'
import { plotStatusesToOrder } from '@/configurations/aquarius'
import api from '@/api'
import bus from '@/plugins/bus'
import { setExtentToPlot } from '@/utils/plots'
import { AsidePanelState } from '@/views/MapView/mapView'
import { generatePlotImage } from '@/views/MapView/generatePlotImage'

function getState() {
  return {
    asidePanelState: AsidePanelState.PLOTS_LIST,

    basin: Basin.FAR_EAST,
    visibleViewExtent: null,

    plots: [],
    favoritePlots: {},

    selectedPlotId: null,
    drawnPlot: null,

    isPlotDrawingActive: false,
    isPlotFeatureDrawn: false,
    plotCoordinates: null, // EPSG:4326
    plotArea: null // ha
  }
}

const initialState = getState()

export default {
  namespaced: true,

  state: initialState,

  getters: {
    plotsIndex(state) {
      return _.keyBy(state.plots, 'id')
    },

    sortedPlots(state) {
      return _.orderBy(
        state.plots,
        [(plot) => plotStatusesToOrder[plot.status] ?? plotStatusesToOrder[PlotStatus.UNKNOWN], 'date'],
        ['acs', 'desc']
      )
    },

    plotIdsSet(state, getters) {
      return new Set(Object.keys(getters.plotsIndex))
    },

    selectedPlot(state, getters) {
      return getters.plotsIndex[state.selectedPlotId]
    }
  },

  mutations: {
    ...make.mutations(initialState),

    resetState(state) {
      Object.assign(state, getState())
    },

    addPlot(state, plot) {
      state.plots.push(plot)
    },

    replacePlot(state, plot) {
      const index = state.plots.findIndex((plot_) => plot_.id === plot.id)
      state.plots.splice(index, 1, plot)
    },

    deletePlot(state, plotId) {
      const index = state.plots.findIndex((plot) => plot.id === plotId)
      state.plots.splice(index, 1)
    },

    setPlotFavorite(state, { plotId, isFavorite }) {
      if (isFavorite) {
        Vue.set(state.favoritePlots, plotId, null)
      } else {
        Vue.delete(state.favoritePlots, plotId)
      }
    }
  },

  actions: {
    resetState({ commit }) {
      commit('resetState')
    },

    setAsidePanelState({ state, commit, dispatch }, [asidePanelState, payload = undefined]) {
      const initialState = getState()

      switch (state.asidePanelState) {
        case AsidePanelState.PLOT: {
          commit('setSelectedPlotId', initialState.selectedPlotId)
          const hasDrawnPlot = !!state.drawnPlot
          commit('setDrawnPlot', initialState.drawnPlot)
          hasDrawnPlot && bus.emit('map/refreshPlotLayers', false) // remove drawn plot from map
          break
        }

        case AsidePanelState.CREATE_PLOT:
          commit('setIsPlotDrawingActive', initialState.isPlotDrawingActive)
          commit('setIsPlotFeatureDrawn', initialState.isPlotFeatureDrawn)
          commit('setPlotCoordinates', initialState.plotCoordinates)
          commit('setPlotArea', initialState.plotArea)
      }

      switch (asidePanelState) {
        case AsidePanelState.PLOT: {
          const { plotId, drawnPlot } = payload
          if (plotId) {
            commit('setSelectedPlotId', plotId)
          } else {
            commit('setDrawnPlot', drawnPlot)
            bus.emit('map/refreshPlotLayers', false) // add drawn plot onto map
          }
          break
        }

        case AsidePanelState.CREATE_PLOT:
          commit('setIsPlotDrawingActive', true)
      }

      commit('setAsidePanelState', asidePanelState)
    },

    async fetchPlots({ commit, dispatch }) {
      const plots = await api.backend.plots.get_plots()
      dispatch('fetchFavoritePlots')

      plots.forEach((plot) => setExtentToPlot(plot)) // required for filtration of plots
      commit('setPlots', plots)

      bus.emit('map/refreshPlotLayers')
    },

    async fetchFavoritePlots({ commit }) {
      if (!Portal.auth.authenticated || Portal.auth.user.pending_email_confirmation) {
        return
      }

      const favoritePlots = await api.backend.plots.get_favorite_plots()
      commit(
        'setFavoritePlots',
        _.mapValues(_.invert(favoritePlots), () => null)
      )
    },

    async createPlot({ commit }, { geometry, coordinates }) {
      const image = await generatePlotImage(geometry.coordinates)
      const plot = await api.backend.plots.create_plot({
        plot: { geometry, coordinates, image },
        save: true
      })
      commit('addPlot', plot)
      commit('setPlotFavorite', { plotId: plot.id, isFavorite: plot.id }) // new plot is favorite by default

      bus.emit('map/refreshPlotLayers')
      return plot
    },

    async deletePlot({ commit }, plotId) {
      await api.backend.plots.delete_plot({
        id: plotId
      })
      commit('deletePlot', plotId)

      bus.emit('map/refreshPlotLayers')
    },

    async sendPlotToTradingPlatform({ commit }, plotId) {
      const result = await api.backend.plots.send_plot_to_rts({ id: plotId })
      commit('replacePlot', result.plot)

      bus.emit('map/refreshPlotLayers')

      return result.rts_url
    },

    async setPlotFavorite({ commit }, { plotId, isFavorite }) {
      await api.backend.plots.set_favorite_plot({ id: plotId, is_favorite: isFavorite })
      commit('setPlotFavorite', { plotId, isFavorite })
    }
  }
}

import _ from 'lodash'
import { make } from 'vuex-pathify'
import { BannerType } from '@/enums/aquarius'
import api from '@/api'
import bus from '@/plugins/bus'
import { AsidePanelState, bannerTypeToPageTypes } from '@/views/BannersManagementView/bannersManagement'

function getState() {
  const bannerType = BannerType.ADVERTISING
  return {
    asidePanelState: AsidePanelState.BANNERS_LIST,
    bannerType,
    bannerPageType: bannerTypeToPageTypes[bannerType][0],

    banners: [],

    bannerId: null
  }
}

const initialState = getState()

export default {
  namespaced: true,

  state: initialState,

  getters: {
    bannersIndex(state) {
      return _.keyBy(state.banners, 'id')
    },

    banner(state, getters) {
      return getters.bannersIndex[state.bannerId]
    }
  },

  mutations: {
    ...make.mutations(initialState),

    resetState(state) {
      Object.assign(state, getState())
    },

    addBanner(state, banner) {
      state.banners.unshift(banner)
    },

    replaceBanner(state, banner) {
      const index = state.banners.findIndex((banner_) => banner_.id === banner.id)
      state.banners.splice(index, 1, banner)
    },

    removeBanner(state, bannerId) {
      const index = state.banners.findIndex((banner_) => banner_.id === bannerId)
      state.banners.splice(index, 1)
    }
  },

  actions: {
    resetState({ commit }) {
      commit('resetState')
    },

    async init({ dispatch }) {
      await dispatch('setBannerType', BannerType.ADVERTISING)
    },

    async setBannerType({ commit, dispatch }, bannerType) {
      commit('setBannerType', bannerType)
      commit('setBannerPageType', bannerTypeToPageTypes[bannerType][0])
      commit('setAsidePanelState', AsidePanelState.BANNERS_LIST)
      await dispatch('fetchBanners')
    },

    setAsidePanelState({ state, commit }, [asidePanelState, payload = undefined]) {
      switch (state.asidePanelState) {
        case AsidePanelState.UPDATE_BANNER:
          commit('setBannerId', getState().asidePanelState)
      }

      switch (asidePanelState) {
        case AsidePanelState.UPDATE_BANNER:
          commit('setBannerId', payload)
      }

      commit('setAsidePanelState', asidePanelState)
    },

    async fetchBanners({ state, commit }) {
      commit('setBanners', [])
      const banners = await api.backend.banners.get_banners({ type: state.bannerType })
      commit('setBanners', banners)
    },

    async createBanner({ state, commit }, { file, url }) {
      const banner = await api.backend.banners.create_banner({ type: state.bannerType, file, url })
      commit('addBanner', banner)
    },

    async updateBanner({ commit }, data) {
      const banner = await api.backend.banners.update_banner(data)
      commit('replaceBanner', banner)
    },

    async deleteBanner({ commit }, bannerId) {
      await api.backend.banners.delete_banner({ id: bannerId })
      commit('removeBanner', bannerId)

      bus.emit('bannersManagement/bannerDeleted', bannerId)
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { setRouteTitle } from 'geoportal/src/plugins/router'
import { AccountType, Basin } from '@/enums/aquarius'
import RouteChildrenHolder from '@/router/RouteChildrenHolder'
import { UiService } from '@/services/UiService'

const FORBIDDEN_ERROR_PAGE_ROUTE = '/error/403'
const NOT_FOUND_ERROR_PAGE_ROUTE = '/error/404'

function checkAccountType(...accountTypes) {
  accountTypes = new Set(accountTypes)
  return (user) => !!user && accountTypes.has(user.account_type)
}

const routes = [
  {
    path: '/',
    redirect: { path: '/map', query: { basin: Basin.FAR_EAST } }
  },

  {
    path: '/map',
    component: () => import(/* webpackChunkName: "MapView" */ '@/views/MapView/MapView'),
    meta: { isCompactMode: true, titleTr: 'pages.titles.map' }
  },

  {
    path: '/plots-list',
    component: () => import(/* webpackChunkName: "PlotsListView" */ '@/views/PlotsListView'),
    meta: { titleTr: 'pages.titles.plotsList' }
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "FaqView" */ '@/views/FaqView/FaqView'),
    meta: { titleTr: 'pages.titles.faq' }
  },
  // {
  //   path: '/portal-description',
  //   component: () => import(/* webpackChunkName: "PortalDescriptionView" */ '@/views/PortalDescriptionView'),
  //   meta: { titleTr: 'pages.titles.portalDescription' }
  // },
  {
    path: '/monitoring',
    component: () => import(/* webpackChunkName: "MonitoringView" */ '@/views/MonitoringView'),
    meta: { titleTr: 'pages.titles.monitoring' }
  },
  {
    path: '/feedback',
    component: () => import(/* webpackChunkName: "FeedbackView" */ '@/views/FeedbackView/FeedbackView'),
    meta: { titleTr: 'pages.titles.feedback' }
  },

  {
    path: '/login',
    component: () => import(/* webpackChunkName: "UserLoginView" */ '@/views/UserLoginView'),
    meta: { titleTr: 'pages.titles.login' }
  },
  {
    path: '/registration/:step?',
    component: () =>
      import(/* webpackChunkName: "UserRegistrationView" */ '@/views/UserRegistrationView/UserRegistrationView'),
    props: true,
    meta: { titleTr: 'pages.titles.registration' }
  },
  {
    path: '/password-reset',
    component: () => import(/* webpackChunkName: "PasswordResetView" */ '@/views/PasswordResetView'),
    meta: { auth: false, titleTr: 'pages.titles.passwordReset' }
  },

  {
    path: '/user',
    redirect: '/user/profile',
    component: RouteChildrenHolder,
    meta: { auth: true },
    children: [
      {
        path: 'profile/:userId?',
        component: () => import(/* webpackChunkName: "UserProfileView" */ '@/views/UserProfileView/UserProfileView'),
        props: true,
        meta: { titleTr: 'pages.titles.userProfile' }
      },
      {
        path: 'notifications',
        component: () => import(/* webpackChunkName: "UserNotificationsView" */ '@/views/UserNotificationsView'),
        meta: {
          authPredicate: checkAccountType(AccountType.ADMIN, AccountType.INVESTOR),
          titleTr: 'pages.titles.userNotifications'
        }
      }
    ]
  },
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "UsersTableView" */ '@/views/UsersTableView'),
    meta: { authPredicate: checkAccountType(AccountType.ADMIN), titleTr: 'pages.titles.users' }
  },
  {
    path: '/layers-management',
    component: () =>
      import(/* webpackChunkName: "LayersManagementView" */ '@/views/LayersManagementView/LayersManagementView'),
    meta: {
      authPredicate: checkAccountType(AccountType.ADMIN, AccountType.FOIV, AccountType.MO),
      isCompactMode: true,
      titleTr: 'pages.titles.layersManagement'
    }
  },
  {
    path: '/banners-management',
    component: () =>
      import(/* webpackChunkName: "BannersManagementView" */ '@/views/BannersManagementView/BannersManagementView'),
    meta: {
      authPredicate: checkAccountType(AccountType.ADMIN),
      isCompactMode: true,
      titleTr: 'pages.titles.bannersManagement'
    }
  },
  {
    path: '/monitoring-settings',
    component: () => import(/* webpackChunkName: "MonitoringSettingsView" */ '@/views/MonitoringSettingsView'),
    meta: { authPredicate: checkAccountType(AccountType.ADMIN), titleTr: 'pages.titles.monitoringSettings' }
  },
  {
    path: '/rts-settings',
    component: () => import(/* webpackChunkName: "RtsSettingsView" */ '@/views/RtsSettingsView'),
    meta: { authPredicate: checkAccountType(AccountType.ADMIN), titleTr: 'pages.titles.rtsSettings' }
  },
  {
    path: '/portal-settings',
    component: () => import(/* webpackChunkName: "PortalSettingsView" */ '@/views/PortalSettingsView'),
    meta: { authPredicate: checkAccountType(AccountType.ADMIN), titleTr: 'pages.titles.portalSettings' }
  },

  {
    path: '/error/:errorCode',
    component: () => import(/* webpackChunkName: "ErrorPageView" */ '../views/ErrorPageView'),
    props: true,
    meta: { titleTr: 'pages.titles.errorPage' }
  },
  {
    path: '*',
    redirect: NOT_FOUND_ERROR_PAGE_ROUTE
  }
]

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  setRouteTitle(to, from, next)

  UiService.setIsCompactMode(to.meta.isCompactMode ?? false)
})

router.goToForbiddenErrorPage = function () {
  this.push(FORBIDDEN_ERROR_PAGE_ROUTE)
}
router.goToNotFoundErrorPage = function () {
  this.push(NOT_FOUND_ERROR_PAGE_ROUTE)
}

export default router

const APP_KEY_PREFIX = 'aquarius'

export function getStoredValueKey(key) {
  return `${APP_KEY_PREFIX}/${key}`
}

export function getStoredValue(key, defaultValue = null) {
  key = getStoredValueKey(key)
  let value = localStorage.getItem(key)

  try {
    value = JSON.parse(value)
  } catch {
    return defaultValue
  }

  return value === null ? defaultValue : value
}

export function setStoredValue(key, value) {
  key = getStoredValueKey(key)
  value = JSON.stringify(value)
  localStorage.setItem(key, value)
}

export function removeStoredValue(key) {
  key = getStoredValueKey(key)
  localStorage.removeItem(key)
}

import _ from 'lodash'
import { EMPTY_VALUE_PLACEHOLDER } from '@/configurations/common'
import { plotPropertiesPrecision } from '@/configurations/aquarius'
import i18n from '@/i18n'
import { formatNumber } from '@/utils/common'

export function formatParameterValue(value, formatter) {
  if (_.isNil(value)) {
    return EMPTY_VALUE_PLACEHOLDER
  }

  return formatter(value)
}

export function formatValue(value) {
  return formatParameterValue(value, _.identity)
}

export function formatMoney(value) {
  return formatParameterValue(value, (v) =>
    Number(v)
      .toLocaleString('ru', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: plotPropertiesPrecision.money,
        maximumFractionDigits: plotPropertiesPrecision.money
      })
      .replace(',', '.')
  )
}

export function formatCatch(value) {
  return formatParameterValue(value, (v) => `${formatNumber(v, plotPropertiesPrecision.weight)} т`)
}

export function formatCultivationPeriod(value) {
  return formatParameterValue(value, (v) => `до ${v} лет`)
}

export function contractTermFormatter(value) {
  return formatParameterValue(value, (v) => `${v} лет`)
}

export const plotPropertiesFormatters = {
  name: formatValue,
  starting_price: formatMoney,
  deposit: formatMoney,
  auction_step: formatMoney,
  pasturable_catch_t: formatCatch,
  industrial_catch_t: formatCatch,
  pasturable_cultivation_period_y: formatCultivationPeriod,
  industrial_cultivation_period_y: formatCultivationPeriod,
  water_body: formatValue,
  federal_subject: formatValue,
  water_use: formatValue,
  contract_term_y: contractTermFormatter
}

export function formatPlotProperties(plot) {
  return Object.entries(plotPropertiesFormatters).reduce(
    (acc, [propertyName, formatter]) => {
      acc[propertyName] = formatter(plot[propertyName])
      return acc
    },
    { ...plot }
  )
}

export const plotPrintConfiguration = [
  { id: 'name', label: 'ID' },
  {
    id: 'area_ha',
    label: 'Площадь',
    formatter: (area_ha) => `${formatNumber(area_ha, plotPropertiesPrecision.area)} га`
  },
  { id: 'status', label: 'Статус', formatter: (status) => i18n.t(`aquarius.plotStatus.${status}`) },
  { id: 'water_body', label: 'Акватория' },
  { id: 'federal_subject', label: 'Регион' },
  { id: 'starting_price', label: 'Начальная цена' },
  { id: 'deposit', label: 'Задаток' },
  { id: 'auction_step', label: 'Шаг аукциона' },
  { id: 'pasturable_catch_t', label: 'Ежегодный объем изъятия аквакультуры (пастбищная)' },
  { id: 'industrial_catch_t', label: 'Ежегодный объем изъятия аквакультуры (индустриальная)' },
  { id: 'pasturable_cultivation_period_y', label: 'Период выращивания аквакультуры (пастбищная)' },
  { id: 'industrial_cultivation_period_y', label: 'Период выращивания аквакультуры (индустриальная)' },
  { id: 'water_use', label: 'Водопользование' },
  { id: 'contract_term_y', label: 'Срок' }
]

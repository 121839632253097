import { apiHelper } from '@/api/apiHelper'

export const monitoring = {
  get_state() {
    return apiHelper.post('/backend/monitoring/get_state')
  },

  get_log({ filters = undefined, sorting = undefined, offset = undefined, limit = undefined } = {}) {
    return apiHelper.post('/backend/monitoring/get_log', {
      data: { filters, sorting, offset, limit }
    })
  }
}

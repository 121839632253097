<script>
  import _ from 'lodash'
  import { VTextField } from 'vuetify/lib'

  // WARN: derived bf-frontend

  // e.g., "-", "-1.", "1."
  const INTERMEDIATE_FRACTIONAL_VALUE_REGEX = /^-?(\d+\.)?$/u
  // also considers if value has reasonable number of places in fraction
  const COMPLETE_VALUE_REGEX = /^-?\d+(\.\d{1,15})?$/u

  function checkIfSafeNumber(value) {
    return value >= Number.MIN_SAFE_INTEGER && value <= Number.MAX_SAFE_INTEGER
  }

  export default {
    name: 'NumberInput',
    inheritAttrs: false,

    props: {
      value: {
        type: Number,
        default: null
      },
      min: {
        type: Number,
        default: Number.MIN_SAFE_INTEGER
      },
      max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER
      }
    },

    data() {
      return {
        lastSafeValue: null
      }
    },

    methods: {
      input(value) {
        if (value === this.value) {
          return
        }

        this.lastSafeValue = value
        this.$emit('input', value)
      },

      getInputComponent() {
        return this.$children[0]
      },

      resetInputToLastSafeValue() {
        const inputCmp = this.getInputComponent()
        inputCmp.internalValue = this.lastSafeValue
      },

      applyRestrictions(value) {
        return _.clamp(value, this.min, this.max)
      },

      handleInput(value) {
        if (value === '' || value == null) {
          this.input(null)
          return
        }

        // value can be number if it was set programmatically
        value = String(value)

        // allows to input comma instead of dot
        value = value.replace(',', '.')

        if (INTERMEDIATE_FRACTIONAL_VALUE_REGEX.test(value)) {
          return
        }

        if (COMPLETE_VALUE_REGEX.test(value)) {
          const parsedValue = parseFloat(value)

          if (checkIfSafeNumber(parsedValue)) {
            const restrictedValue = this.applyRestrictions(parsedValue)
            this.input(restrictedValue)

            // ensure the correct internal value
            if (restrictedValue !== parsedValue) {
              this.resetInputToLastSafeValue()
            }

            return
          }
        }

        this.resetInputToLastSafeValue()
      },

      handleFocusBlur() {
        const inputCmp = this.getInputComponent()
        const value = inputCmp.internalValue

        if (!COMPLETE_VALUE_REGEX.test(value)) {
          this.resetInputToLastSafeValue()
        }
      }
    },

    render(h) {
      return h(VTextField, {
        attrs: {
          ...this.$attrs,
          value: this.value,
          min: this.min,
          max: this.max,
          type: 'text',
          inputmode: 'numeric'
        },
        staticClass: 'number-input',
        on: {
          ...this.$listeners,
          input: this.handleInput,
          blur: this.handleFocusBlur
        },
        scopedSlots: this.$scopedSlots
      })
    }
  }
</script>

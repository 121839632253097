import Portal from './portal'

/** @type {PluginObject} */
export default {
  /**
   * @param {VueConstructor} Vue
   * @param {Object} [options]
   * @param {Object} [options.settings]
   * @param {Object} [options.i18n]
   * @param {Object} [options.store]
   * @param {Object} [options.router]
   * @param {Object} [options.auth]
   */
  install(Vue, options = {}) {
    Portal.settings = options.settings ?? null
    Portal.i18n = options.i18n ?? null
    Portal.store = options.store ?? null
    Portal.router = options.router ?? null
    Portal.auth = options.auth ?? null
  }
}

import _ from 'lodash'
import { polygon as createPolygonFeature } from '@turf/helpers'
import GeoJSON from 'ol/format/GeoJSON'
import { EPSG_3857, EPSG_4326 } from 'geoportal/src/utils/geo'
import { plotPropertiesPrecision } from '@/configurations/aquarius'

export const PLOT_POINTS_NUMBER = 4
export const CAN_BE_BOUND_TO_COASTLINE_DISTANCE = 50 // meters
export const MIN_PLOT_AREA = 100 // hectares
export const MAX_PLOT_AREA = 5000 // hectares

export function convertSquareMetersToHectares(value) {
  return value / 10_000
}

export function formatPlotArea(value) {
  return _.ceil(convertSquareMetersToHectares(value), plotPropertiesPrecision.area)
}

export function setExtentToPlot(plot) {
  const feature = createPolygonFeature(plot.geometry.coordinates)
  const olFeature = new GeoJSON().readFeature(feature, {
    dataProjection: EPSG_4326,
    featureProjection: EPSG_3857
  })
  plot.extent = olFeature.getGeometry().getExtent()
}

export const DateTimeType = Object.freeze({
  DATETIME: 'datetime',
  DATE: 'date',
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  TIME: 'time'
})

// Unicode Tokens
export const dateTimeTypesToValueFormats = Object.freeze({
  [DateTimeType.DATETIME]: 'yyyy-MM-dd HH:mm:ss',
  [DateTimeType.DATE]: 'yyyy-MM-dd',
  [DateTimeType.YEAR]: 'yyyy',
  [DateTimeType.MONTH]: 'yyyy-MM',
  [DateTimeType.WEEK]: 'ww',
  [DateTimeType.TIME]: 'HH:mm:ss'
})

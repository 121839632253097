<template>
  <v-app>
    <the-app-bar />

    <v-main>
      <router-view v-if="ready" />
    </v-main>

    <the-app-footer />

    <the-messages-queue />
    <plot-printer />
  </v-app>
</template>

<script>
  import { call } from 'vuex-pathify'
  import TheAppBar from '@/views/global/TheAppBar/TheAppBar'
  import TheAppFooter from '@/views/global/TheAppFooter'
  import TheMessagesQueue from '@/components/TheMessagesQueue'
  import PlotPrinter from '@/components/PlotPrinter'

  export default {
    name: 'App',
    components: { TheAppBar, TheAppFooter, TheMessagesQueue, PlotPrinter },

    data() {
      return {
        settingsAreFetched: false,
        VUE_APP_PORTAL_TITLE: process.env.VUE_APP_PORTAL_TITLE
      }
    },

    computed: {
      ready() {
        return this.$auth.ready && this.settingsAreFetched
      }
    },

    async created() {
      await this.fetchPortalSettings()
      this.settingsAreFetched = true
    },

    methods: {
      ...call({
        fetchPortalSettings: 'fetchPortalSettings'
      })
    }
  }
</script>

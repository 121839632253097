<template>
  <v-footer class="justify-center" :height="40" color="white">
    <div class="logos-wrapper d-flex justify-space-evenly">
      <a :href="rtsUrl" target="_blank" rel="noreferrer noopener">
        <v-img :src="require('@/assets/rts_logo.svg')" :width="85" :height="28" contain />
      </a>

      <a href="https://kronshtadt.ru" target="_blank" rel="noreferrer noopener">
        <v-img :src="require('@/assets/kronshtadt_logo.svg')" :width="44" :height="28" contain />
      </a>
    </div>
  </v-footer>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'TheAppFooter',

    computed: {
      ...get({
        portalSettings: 'portalSettings'
      }),

      rtsUrl() {
        return this.portalSettings?.rts?.base_url
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-footer {
    .logos-wrapper {
      width: 520px;
      max-width: 100%;
    }
  }
</style>

import Vue from 'vue'
import NumberInput from '@/components/NumberInput'
import TextBtn from '@/components/TextBtn'
import PasswordInput from '@/components/PasswordInput'
import PageContainer from '@/components/PageContainer'
import TitledCard from '@/components/TitledCard'
import FictitiousLoginForm from '@/components/FictitiousLoginForm'
import BaseForm from '@/components/BaseForm/BaseForm'

Vue.component(NumberInput.name, NumberInput)
Vue.component(TextBtn.name, TextBtn)
Vue.component(PasswordInput.name, PasswordInput)
Vue.component(PageContainer.name, PageContainer)
Vue.component(TitledCard.name, TitledCard)
Vue.component(FictitiousLoginForm.name, FictitiousLoginForm)
Vue.component(BaseForm.name, BaseForm)

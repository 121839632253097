export default {
  logIn: 'Войти',
  logOut: 'Выйти',

  username: 'Логин',
  password: 'Пароль',

  authenticationCheck: 'Аутентификация...',

  errors: {
    usernameAndPasswordAreRequired: 'Логин и пароль обязательны',
    usernameIsRequired: 'Логин обязателен',
    passwordIsRequired: 'Пароль обязателен',
    auth: 'Во время входа произошла ошибка',
    logout: 'Во время выхода произошла ошибка'
  }
}

/** Explanation: https://alligator.io/vuejs/global-event-bus */
import { beforeDestroy } from '../utils/vue'

export function createBus(Vue) {
  /** @type {Bus} */
  const BusConstructor = Vue.extend({
    created() {
      this.on = this.$on
      this.off = this.$off
      this.emit = this.$emit
      this.once = this.$once
    },

    methods: {
      /**
       * Sets listener for a custom event on the bus.
       * Removes that listener on beforeDestroy hook of given component
       * @param {Vue} cmp
       * @param {string} event
       * @param {Function} cb
       */
      listen(cmp, event, cb) {
        this.$on(event, cb)
        beforeDestroy(cmp, () => this.$off(event, cb))
      }
    }
  })

  return new BusConstructor()
}

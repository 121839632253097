<template>
  <div class="page-container">
    <div class="content-container">
      <header v-if="isHeaderVisible">
        <div v-if="!isTouchDevice" class="page-title">{{ title }}</div>

        <slot name="title-content" />
      </header>

      <slot />
    </div>
  </div>
</template>

<script>
  import { checkIfTouchDevice } from '@/utils/common'

  export default {
    name: 'PageContainer',

    props: {
      title: {
        type: String,
        default: null
      },
      forceHeader: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isTouchDevice() {
        return checkIfTouchDevice()
      },

      isHeaderVisible() {
        if (this.isTouchDevice) {
          return this.forceHeader
        } else {
          return !!this.title
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-container {
    display: flex;
    justify-content: center;

    .content-container {
      display: flex;
      flex-direction: column;
      width: 1400px;
      max-width: 100%;
      min-width: 0;
      margin: 40px 20px 20px 20px;

      header {
        display: flex;
        margin-top: 16px;
        margin-bottom: 20px;
      }
    }
  }
</style>

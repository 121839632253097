export const UiService = {
  isCompactMode: null,

  init() {
    this.setIsCompactMode(false)
  },

  setIsCompactMode(isCompactMode) {
    if (this.isCompactMode === isCompactMode) {
      return
    }

    document.documentElement.style.setProperty('--global-scrollbar-overflow', isCompactMode ? 'hidden' : 'scroll')
    document.documentElement.style.setProperty('--main-container-height', isCompactMode ? 'calc(100vh - 48px)' : '')
    document.documentElement.style.setProperty('--footer-display', isCompactMode ? 'none' : 'flex')

    this.isCompactMode = isCompactMode
  }
}

import { apiHelper } from '@/api/apiHelper'

export const users = {
  // create_user(data) {
  //   return apiHelper.post('/backend/users/create_user', {
  //     data
  //   })
  // },

  update_user(data) {
    return apiHelper.post('/backend/users/update_user', {
      data
    })
  },

  // delete_user({ id }) {
  //   return apiHelper.post('/backend/users/delete_user', {
  //     data: { id }
  //   })
  // },

  get_user({ id }) {
    return apiHelper.post('/backend/users/get_user', {
      data: { id }
    })
  },

  get_users({ filters = undefined, sorting = undefined, offset = undefined, limit = undefined } = {}) {
    return apiHelper.post('/backend/users/get_users', {
      data: { filters, sorting, offset, limit }
    })
  },

  get_current_user() {
    return apiHelper.post('/backend/users/get_current_user')
  },

  update_current_user(data) {
    return apiHelper.post('/backend/users/update_current_user', {
      data
    })
  },

  change_own_password({ old_password, new_password }) {
    return apiHelper.post('/backend/users/change_own_password', {
      data: { old_password, new_password }
    })
  },

  register_user({ user_data, registration_code = undefined }) {
    return apiHelper.post('/backend/users/register_user', {
      data: { user_data, registration_code }
    })
  },

  send_email_confirmation() {
    return apiHelper.post('/backend/users/send_email_confirmation')
  },

  confirm_email({ confirmation_code }) {
    return apiHelper.post('/backend/users/confirm_email', {
      data: { confirmation_code }
    })
  },

  send_reset_password_code({ login }) {
    return apiHelper.post('/backend/users/send_reset_password_code', {
      data: { login }
    })
  },

  reset_password({ reset_code, new_password }) {
    return apiHelper.post('/backend/users/reset_password', {
      data: { reset_code, new_password }
    })
  }
}

export function formatNumber(value, precision) {
  return value
    .toLocaleString('ru', { maximumFractionDigits: precision, minimumFractionDigits: precision })
    .replace(',', '.')
}

export function coalesceNilToEmptyString(value) {
  return value == null ? '' : value
}

export function checkIfTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

import baseAuth from 'geoportal/src/i18n/modules/auth.ru'
import common from '@/i18n/modules/common.ru'
import users from '@/i18n/modules/users.ru'
import aquarius from '@/i18n/modules/aquarius.ru'
import layersManagement from '@/i18n/modules/layersManagement.ru'
import errorPage from '@/i18n/modules/errorPage.ru'
import pages from '@/i18n/modules/pages.ru'

export default {
  auth: baseAuth,
  common,
  users,
  aquarius,
  layersManagement,
  errorPage,
  pages
}

import _ from 'lodash'

/**
 * Returns deep object copy
 * May be faster than other methods to create deep copy
 * Doesn't support circular structures
 * @param {*} value
 * @returns {*}
 */
export function cloneObject(value) {
  return JSON.parse(JSON.stringify(value))
}

/**
 * Returns deep object copy
 * @param {*} value
 * @returns {*}
 */
export function cloneDeep(value) {
  if (!value) {
    return value
  } else if (Array.isArray(value)) {
    return value.map(cloneDeep)
  } else if (_.isPlainObject(value)) {
    return _.mapValues(value, cloneDeep)
  } else {
    return value
  }
}

const reNumber = /^[+-]?\d+\.?\d*$/u

/**
 * Checks if given value (including string) is a number
 * Related topic: https://stackoverflow.com/q/175739
 * Handles next cases: '01', '1.0', '1.10', '1.'
 * @param {*} value
 * @returns {boolean}
 */
export function isNumeric(value) {
  if (_.isString(value)) {
    // TODO: '.1', '-.1', '+-1', '1e1', '0x1'
    const parsedNumber = parseFloat(value)
    return !Number.isNaN(parsedNumber) && Number.isFinite(parsedNumber) && reNumber.test(value)
  } else {
    return _.isNumber(value)
  }
}

/**
 * Checks if given value (including string) is an integer
 * @param {*} value
 * @returns {boolean}
 */
export function isInteger(value) {
  if (_.isString(value)) {
    return Number.isInteger(parseFloat(value)) && isNumeric(value)
  } else {
    return Number.isInteger(value)
  }
}

/**
 * Checks if given value (including string) is a floating point number
 * @param {*} value
 * @returns {boolean}
 */
export function isFloat(value) {
  if (_.isNumber(value)) {
    const fraction = value % 1
    return !Number.isNaN(fraction) && fraction !== 0
  } else if (_.isString(value)) {
    return isNumeric(value) && parseFloat(value) % 1 !== 0
  }
  return false
}

/**
 * Removes value from array
 * @param {Array} arr
 * @param {*} value
 */
export function removeValueFromArray(arr, value) {
  const i = arr.findIndex((v) => v === value)
  if (i === -1) {
    return
  }
  arr.splice(i, 1)
  return arr
}

/**
 * @param {Array} array
 * @param {Array} values
 */
export function removeValuesFromArray(array, values) {
  if (!values.length) {
    return array
  }
  const valuesSet = new Set(values)
  let i = 0

  while (i < array.length) {
    const item = array[i]

    if (valuesSet.has(item)) {
      array.splice(i, 1)
    } else {
      i += 1
    }
  }

  return array
}

/**
 * @param {*} value
 * @returns {Function}
 */
export function functify(value) {
  return _.isFunction(value) ? value : _.constant(value)
}

/**
 * Returns value from object by given key then remove it
 * Returns default value if value doesn't exist
 * @param {Object} obj
 * @param {PropertyKey} key
 * @param {*} [defaultValue]
 * @returns {*}
 */
export function popFromObject(obj, key, defaultValue) {
  if (key in obj) {
    const val = obj[key]
    delete obj[key]
    return val
  } else {
    return defaultValue
  }
}

/**
 * Returns value from object not considering the case of property
 * Returns default value if value doesn't exist
 * @param {Object} value
 * @param {string} key
 * @param {*} [defaultValue]
 * @returns {*}
 */
export function getCaseInsensible(value, key, defaultValue) {
  if (!value) {
    return
  }
  if (key in value) {
    return value[key]
  }
  key = key.toLowerCase()
  const paramKey = Object.keys(value).find((k) => k.toLowerCase() === key)
  return paramKey ? value[paramKey] : defaultValue
}

/**
 * Creates object with given keys
 * If initializer is function then it will be used to initialize values otherwise given value will be used for values
 * @example
 * objectFromKeys(['a', 'b'], null)
 * objectFromKeys(['a', 'b'], () => undefined)
 * objectFromKeys(['a', 'b'], key => key.toUpperCase())
 * @param {Array} keys
 * @param {*} [initializer]
 * @returns {Object}
 */
export function objectFromKeys(keys, initializer = _.identity) {
  initializer = functify(initializer)
  return keys.reduce((acc, key, index) => {
    acc[key] = initializer(key, index, keys)
    return acc
  }, {})
}

/**
 * @param {Array} pairs
 * @returns {Object}
 */
export function groupPairs(pairs) {
  const result = {}

  for (const pair of pairs) {
    const [key, value] = pair

    if (result[key]) {
      result[key].push(value)
    } else {
      result[key] = [value]
    }
  }

  return result
}

/**
 * Checks if value is undefined, null, NaN, empty string, empty array, empty set or empty map
 * @param {*} value
 * @returns {boolean}
 */
export function isEmptyValue(value) {
  if (_.isNil(value) || Number.isNaN(value)) {
    return true
  } else if (Array.isArray(value) || _.isString(value)) {
    return value.length === 0
  } else if (_.isPlainObject(value)) {
    return Object.keys(value).length === 0
  } else if (value instanceof Set || value instanceof Map) {
    return value.size === 0
  }
  return false
}

/**
 * Returns fractional part of passed number
 * Result may be inaccurate due floating-point numbers representation
 * @param {number} number
 * @returns {number}
 */
export function getDecimal(number) {
  return number - Math.floor(number)
}

/**
 * @param {Array} array
 * @param {*} iteratee
 * @returns {boolean}
 */
export function allValuesUnique(array, iteratee = _.identity) {
  iteratee = _.iteratee(iteratee)
  const uniqueValues = new Set()

  for (const item of array) {
    const processedItem = iteratee(item)

    if (uniqueValues.has(processedItem)) {
      return false
    }
    uniqueValues.add(processedItem)
  }

  return true
}

/**
 * @param {Array} array
 * @param {Function} [predicate]
 * @returns {number}
 */
export function countIf(array, predicate = _.identity) {
  let result = 0

  for (const item of array) {
    if (predicate(item)) {
      result += 1
    }
  }

  return result
}

/**
 * Checks whether an array is subset of another one
 * @param {Array} superset
 * @param {Array} subset
 * @returns {boolean}
 */
export function arrayContainsArray(superset, subset) {
  return _.difference(subset, superset).length === 0
}

/**
 * Returns the given object sanitized from pairs with nil values
 * @param {Object} object
 * @returns {Object}
 */
export function pickByNotNilValues(object) {
  return _.pickBy(object, _.negate(_.isNil))
}

/**
 * Creates an object composed of the object properties that are not omitted
 * @param {Object} object
 * @param {Array} properties
 * @returns {Object}
 */
export function omit(object, properties) {
  const propsSet = new Set(properties)
  const result = {}

  for (const key of Object.keys(object)) {
    if (!propsSet.has(key)) {
      result[key] = object[key]
    }
  }

  return result
}

/** @typedef {'ascending'|'descending'|null} SortingOrder */

/** @type {Object.<string, SortingOrder>} */
export const sortingOrder = Object.freeze({
  ascending: 'ascending',
  descending: 'descending',
  none: null
})

/**
 * Sorts array in place by given property key and order
 * If prop is function, it is being used to normalize items
 * @param {Array} arr
 * @param {PropertyKey|PropertyKey[]|Function} prop
 * @param {SortingOrder} [order]
 * @returns {Array}
 */
export function sortByProperty(arr, prop, order = sortingOrder.ascending) {
  const normalize = _.isFunction(prop) ? prop : (item) => _.get(item, prop)
  let func

  if (order === sortingOrder.ascending) {
    func = (a, b) => {
      a = normalize(a)
      b = normalize(b)
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    }
  } else if (order === sortingOrder.descending) {
    func = (a, b) => {
      a = normalize(a)
      b = normalize(b)
      if (a > b) {
        return -1
      }
      if (a < b) {
        return 1
      }
      return 0
    }
  } else {
    return arr
  }

  arr.sort(func)
  return arr
}

/**
 * @param {Array} array
 * @param {Array} refArray
 * Doesn't sort in place
 * Function implies that:
 *  - the given array is subset of referred array;
 *  - each of arrays has only unique items
 */
export function sortByRefArray(array, refArray) {
  const arraySet = new Set(array)
  return refArray.reduce((acc, item) => {
    if (arraySet.has(item)) {
      acc.push(item)
    }
    return acc
  }, [])
}

/**
 * Deeply sorts given object in place
 * @param {Array} arr
 * @param {PropertyKey|PropertyKey[]} prop
 * @param {PropertyKey|PropertyKey[]} childProp
 * @param {SortingOrder} [order]
 * @returns {Array}
 */
export function deepSortByProperty(arr, prop, childProp, order = sortingOrder.ascending) {
  if (order !== sortingOrder.ascending && order !== sortingOrder.descending) {
    return arr
  }

  sortByProperty(arr, prop, order)

  arr.forEach((child) => {
    const subChildren = _.get(child, childProp)
    if (!Array.isArray(subChildren)) {
      return
    }
    deepSortByProperty(subChildren, prop, childProp, order)
  })

  return arr
}

/**
 * Walks deep and calls callback with each obtained child
 * @param {Array} arr
 * @param {PropertyKey|PropertyKey[]} childProp
 * @param {Function} cb - a function which accepts child, its index and path
 *  If cb returns false, then child won't be processed further
 * @param {Array} [path] - path to given children
 */
export function walkDeepByProperty(arr, childProp, cb, path = []) {
  arr.forEach((child, i) => {
    if (cb(child, i, [...path, i]) === false) {
      return
    }
    const subChildren = _.get(child, childProp)
    if (!Array.isArray(subChildren) || !subChildren.length) {
      return
    }
    walkDeepByProperty(subChildren, childProp, cb, [...path, i, childProp])
  })
}

/**
 * @param {Array} tree
 * @param {PropertyKey[]} path
 * @returns {Array}
 */
export function getNodesByPath(tree, path) {
  const nodes = []

  for (let i = 0; i < _.ceil(path.length / 2); i++) {
    const nodePath = path.slice(0, 1 + i * 2)
    const node = _.get(tree, nodePath)
    nodes.push(node)
  }

  return nodes
}

/**
 * Flattens given array, walking deep by property
 * @param {Array} arr
 * @param {PropertyKey|PropertyKey[]} childProp
 * @param {Function} [predicate] - function which accepts child and checks whether to include it
 * @param {Function} [walkPredicate] - function which accepts child and checks whether to process child further.
 *  If walkPredicate returns false, then child won't be processed further
 * @returns {Array}
 */
export function flattenDeepByProperty(arr, childProp, predicate = _.stubTrue, walkPredicate = _.stubTrue) {
  const children = []
  const cb = (child, i, path) => {
    predicate(child, i, path) !== false && children.push(child)
    return walkPredicate(child, i, path)
  }
  walkDeepByProperty(arr, childProp, cb)
  return children
}

/**
 * Returns leaves of tree-like structure
 * @param {Array} arr
 * @param {PropertyKey|PropertyKey[]} childProp
 * @returns {Array}
 */
export function getLeaves(arr, childProp) {
  const leaves = []
  const cb = (child) => {
    const children = _.get(child, childProp)
    if (Array.isArray(children) && children.length) {
      return
    }
    leaves.push(child)
  }
  walkDeepByProperty(arr, childProp, cb)
  return leaves
}

/**
 * @param {Object} value
 * @param {Array} keys
 * @returns {Array}
 */
export function pickAsArray(value, keys) {
  return keys.map((key) => _.get(value, key))
}

/**
 * @param {Array} arr
 * @param {PropertyKey|PropertyKey[]} childProp
 * @param {Function} predicate
 *  Predicate is being called twice for items with children:
 *  on walking down (with original children) and on walking up (with picked children)
 * @param {Array} [path] - path to given children
 * @returns {Array}
 */
export function deepPickBy(arr, childProp, predicate, path = []) {
  const result = []

  arr.forEach((child, i) => {
    const childPath = [...path, i]
    if (predicate(child, i, childPath) === false) {
      return
    }
    const subChildren = _.get(child, childProp)
    const hasSubChildren = Array.isArray(subChildren) && subChildren.length

    if (hasSubChildren) {
      child = { ...child }
      child[childProp] = deepPickBy(subChildren, childProp, predicate, [...childPath, childProp])
      // call predicate once again to check whether processed child should be picked
      // NOTE: could be replaced with pair of predicates: walkDownPredicate and walkUpPredicate
      if (predicate(child, i, childPath) === false) {
        return
      }
    }

    result.push(child)
  })

  return result
}

/**
 * @param {Array} array
 * @param {PropertyKey|PropertyKey[]} childProp
 * @param {Function} callback
 * @returns {Array}
 */
export function deepMap(array, childProp, callback) {
  const result = []
  walkDeepByProperty(array, childProp, (node, i, path) => {
    _.set(result, path, callback(node, i, path))
  })
  return result
}

/**
 * Delays calls of given function, invoking it only if the time from the last call is equal ot greater than timeout
 * Callback will be invoked with array of arguments from all calls
 * @param {Function} cb
 * @param {number} [timeout]
 * @param {boolean} [withResolveCbs]
 * @returns {Function}
 */
export function debounceWithQueue(cb, timeout = 0, withResolveCbs = false) {
  let callsQueue = []
  let timerID = null
  const timeoutCb = () => {
    const payload = callsQueue
    callsQueue = []
    timerID = null
    cb(payload)
  }

  if (withResolveCbs) {
    return (...args) =>
      new Promise((resolve) => {
        const callCb = (callCbValue) => resolve(callCbValue)
        callsQueue.push([args, callCb])
        clearTimeout(timerID)
        timerID = setTimeout(timeoutCb, timeout)
      })
  } else {
    return (...args) => {
      callsQueue.push(args)
      clearTimeout(timerID)
      timerID = setTimeout(timeoutCb, timeout)
    }
  }
}

/**
 * Picks properties from the first object which don't have the same values in the second one
 * Useful to pick not affected properties for update requests
 * @param {Object} first
 * @param {Object} second
 * @returns {Object}
 */
export function getObjectsDifference(first, second) {
  if (!second) {
    return { ...first }
  }
  return _.pickBy(first, (value, key) => !(key in second && _.isEqual(value, second[key])))
}

/**
 * @param {number} wait
 * @returns {Promise<void>}
 */
export function delay(wait) {
  return new Promise((resolve) => {
    setTimeout(resolve, wait)
  })
}

/**
 * @param {Function} predicate
 * @param {number} [maxWait]
 * @param {number} [waitStep]
 * @returns {Promise<boolean>} - returns false if the maximum time is exceeded, otherwise true
 */
export async function delayUntil(predicate, maxWait = Infinity, waitStep = 10) {
  const startTime = Date.now()

  while (!(await predicate())) {
    await delay(waitStep)

    if (Date.now() - startTime >= maxWait) {
      return false
    }
  }

  return true
}

/**
 * @param {string} string
 * @param {Object|Array} parameters
 * @returns {string}
 */
export function formatString(string, parameters) {
  Object.keys(parameters).forEach((parameterName) => {
    string = string.replace(new RegExp(`\\{${parameterName}\\}`, 'ugi'), parameters[parameterName])
  })
  return string
}

import tinycolor from 'tinycolor2'
import { polygon as createPolygonFeature } from '@turf/helpers'
import { Fill, Style } from 'ol/style'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Portal from 'geoportal/src/portal'
import { EPSG_3857, EPSG_4326 } from 'geoportal/src/utils/geo'
import { plotStatusGroups } from '@/configurations/aquarius'
import api from '@/api'

export const BUFFER_ZONES_COLOR = '#90a4ae'
export const FISHING_PLOTS_COLOR = '#be50c8'
export const PERMITTED_ZONES_COLOR = '#4caf50'

export const AsidePanelState = Object.freeze({
  PLOTS_LIST: 'plots_list',
  PLOT: 'plot',
  CREATE_PLOT: 'create_plot'
})

const plotStatusToStyle = plotStatusGroups.reduce((acc, group) => {
  const style = new Style({
    fill: new Fill({
      color: tinycolor(group.color).setAlpha(0.9).toRgbString()
    })
  })
  group.statuses.forEach((status) => {
    acc[status] = style
  })
  return acc
}, {})

const geoJsonReader = new GeoJSON({
  dataProjection: EPSG_4326,
  featureProjection: EPSG_3857
})

export const layerConfigurations = {
  plots: new VectorLayer({
    id: 'plots',
    zIndex: 1001,
    source: new VectorSource({
      loader() {
        let plots = Portal.store.get('map/plots')
        const drawnPlot = Portal.store.get('map/drawnPlot')
        if (drawnPlot) {
          plots = [...plots, drawnPlot]
        }

        const features = plots.map((plot) =>
          createPolygonFeature(plot.geometry.coordinates, { id: plot.id, status: plot.status }, { id: plot.id })
        )
        const olFeatures = geoJsonReader.readFeatures({ type: 'FeatureCollection', features })
        this.addFeatures(olFeatures)
      }
    }),
    style: (feature) => plotStatusToStyle[feature.get('status')]
  }),

  buffer_zones: new VectorLayer({
    id: 'buffer_zones',
    zIndex: 1000,
    source: new VectorSource({
      async loader() {
        const bufferZones = await api.backend.plots.get_buffer_zones()

        const features = bufferZones.map((bufferZone) =>
          createPolygonFeature(
            bufferZone.geometry.coordinates,
            { id: bufferZone.plot_id, plot_id: bufferZone.plot_id },
            { id: bufferZone.plot_id }
          )
        )
        const olFeatures = geoJsonReader.readFeatures({ type: 'FeatureCollection', features })
        this.addFeatures(olFeatures)
      }
    }),
    style: (() => {
      const defaultStyle = new Style({
        fill: new Fill({
          color: tinycolor(BUFFER_ZONES_COLOR).setAlpha(0.6).toRgbString()
        })
      })
      const selectedPlotStyle = new Style({
        fill: new Fill({
          color: tinycolor('#ffffff').setAlpha(0.6).toRgbString()
        }),
        zIndex: 5
      })
      return (feature) => {
        const selectedPlotId = Portal.store.get('map/selectedPlotId')
        return feature.get('plot_id') === selectedPlotId ? selectedPlotStyle : defaultStyle
      }
    })()
  })
}

// initially disable layer visibility in order not to fetch features twice on app initialization
layerConfigurations.buffer_zones.setVisible(false)
